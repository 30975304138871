import { AvField, AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CardTitle, Col, Modal, ModalBody, Row, FormGroup } from "reactstrap"
import Select from "react-select"

import { createDistrict, getAllStates, updateDistrict } from "store/actions"
import { debounce } from "lodash"

const DistrictModal = ({ show, onCloseClick, updateData }) => {
  const [stateSearch, setStateSearch] = useState("")
  const [stateSelect, setStateSelect] = useState("Search state")
  const [stateSelectId, setStateSelectId] = useState("")

  const dispatch = useDispatch()
  // console.log(updateData?._id)
  const handleValidSubmit = (e, v) => {
    const district = {
      ...v,
      state: stateSelectId,
    }
    console.log(v?.state)
    if (updateData?._id) {
      dispatch(updateDistrict(updateData?._id, district, onCloseClick))
    } else {
      dispatch(createDistrict(district, onCloseClick))
    }
  }
  const { loading, states } = useSelector(state => ({
    loading: state.Blogs.loading,
    states: state.Location.states,
  }))
  useEffect(() => {
    dispatch(getAllStates(stateSearch))
  }, [dispatch, stateSearch])
  const debounceStateSearch = debounce(value => setStateSearch(value), 600)
  const handleStateEnters = textEntered => {
    debounceStateSearch(textEntered)
  }
  function handlerStateFinalValue(event) {
    setStateSelect(event.label)
    setStateSelectId(event.value)
    // stateId(event.value)
  }
  const StateOptions = [
    {
      label: "All state",
      id: "",
    },
    {
      options: (states?.total >= 1 ? states?.states : [])?.map(
        (result, index) => ({
          key: index,
          label: result?.name,
          value: result?._id,
        })
      ),
    },
  ]

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
    >
      <ModalBody style={deleteModalBodyStyle} className="p-4">
        <CardTitle>{updateData?._id ? "Update" : "Add New"} District</CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <Col lg={12}>
              <AvField
                name="name"
                type="text"
                placeholder="District name"
                value={updateData?.name}
                className="mb-3"
              />
              <Col className="mb-2 padding-3" lg={12}>
                <FormGroup className="w-350">
                  <div className="ajax-select mt-lg-0 select2-container">
                    <Select
                      onInputChange={handleStateEnters}
                      value={stateSelect}
                      placeholder={stateSelect}
                      onChange={handlerStateFinalValue}
                      options={StateOptions}
                      classNamePrefix="select2-selection"
                      isLoading={loading}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Col>
          </Row>
          <div className="d-flex justify-content-between mt-3 align-items-center">
            <div className="text-center">
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-success ms-2">
                Save
              </button>
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

DistrictModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default DistrictModal

const deleteModalStyle = {
  width: 300,
  minWidth: 150,
}
const deleteModalBodyStyle = {
  minHeight: 100,
}
