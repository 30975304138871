import {
  API_SUCCESS,
  API_FAIL,
  GET_CHARTS_DATA,
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAIL,
  GET_PROMOTER_DASHBOARD_DATA_FAIL,
  GET_PROMOTER_DASHBOARD_DATA_SUCCESS,
  GET_PROMOTER_DASHBOARD_DATA,
  GET_PROMOTER_PROFILE_DETAILS,
  GET_PROMOTER_PROFILE_DETAILS_SUCCESS,
  GET_PROMOTER_PROFILE_DETAILS_FAIL,
  PROMOTER_PROFILE_UPDATE_SUCCESS,
  PROMOTER_PROFILE_UPDATE_FAIL,
  PROMOTER_PROFILE_UPDATE,
} from "./actionTypes"

const INIT_STATE = {
  chartsData: [],
  dashboardData: {},
  promoterDashboardData: {},
  promoterDashboardLoading: false,

  promoterLoading: false,
  promoterProfile: {},
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CHARTS_DATA:
          return {
            ...state,
            chartsData: action.payload.data,
          }
        default:
          return state
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_CHARTS_DATA:
          return {
            ...state,
            chartsDataError: action.payload.error,
          }

        default:
          return state
      }

    //Dashboard Data
    case GET_DASHBOARD_DATA:
      return {
        ...state,
        loading: true,
      }

    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload,
        loading: false,
      }

    case GET_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // promoter profile data

    case GET_PROMOTER_PROFILE_DETAILS:
      return {
        ...state,
        promoterLoading: true,
      }
    case GET_PROMOTER_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        promoterLoading: false,
        promoterProfile: action.payload,
      }
    case GET_PROMOTER_PROFILE_DETAILS_FAIL:
      return {
        ...state,
        promoterLoading: false,
        error: action.payload,
      }

    //promoter dashboard data

    case GET_PROMOTER_DASHBOARD_DATA:
      return {
        ...state,
        promoterDashboardLoading: true,
      }
    case GET_PROMOTER_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        promoterDashboardLoading: false,
        promoterDashboardData: action.payload,
      }
    case GET_PROMOTER_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        promoterDashboardLoading: false,
        error: action.payload,
      }
    case PROMOTER_PROFILE_UPDATE:
      return {
        ...state,
        promoterLoading: true,
      }

    case PROMOTER_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        promoterLoading: false,
        promoterProfile: action.payload,
      }
    case PROMOTER_PROFILE_UPDATE_FAIL:
      return {
        ...state,
        promoterLoading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Dashboard
