import { call, put, takeEvery } from "redux-saga/effects"

// Settings Redux States
import {
  GET_GMAP_PKEY,
  GET_SETTINGS,
  SEARCH_LOCATION,
  UPDATE_SETTINGS,
} from "./actionTypes"
import {
  getSettingsSuccess,
  getSettingsFail,
  updateSettingsSuccess,
  updateSettingsFail,
  getMapPublicKeySuccess,
  getMapPublicKeyFail,
  searchPlacesSuccess,
  searchPlacesFail,
} from "./actions"

//Include Both Helper File with needed methods
import { post, get, ApiPut, del } from "../../helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

function settingsApi({ dashboardRole }) {
  if (dashboardRole === "admin") {
    return get("/settings")
  }
}

function updateSettingsApi({ settings, id }) {
  return ApiPut(`/settings/${id}`, settings)
}

function getGmapKeyAPi() {
  return get("/location/googlemap-api-key")
}
function getSearchLocationAPi(query) {
  return get(`/location/search/${query}`)
}

function* fetchSettings({ payload }) {
  try {
    const response = yield call(settingsApi, payload)
    if (response) {
      sessionStorage.setItem(
        "multiLang",
        response?.multiLanguange?.isMultiLanguage
      )
      yield put(getSettingsSuccess(response))
    }
  } catch (error) {
    console.log(error)
    yield put(getSettingsFail(error))
  }
}

function* onUpdateSettings({ payload }) {
  try {
    const response = yield call(updateSettingsApi, payload)
    yield put(updateSettingsSuccess(response))
    Notification({
      type: "success",
      message: "Settings Updated Successfully!",
      title: "",
    })
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateSettingsFail(error))
  }
}

function* fetchGmapKey() {
  try {
    const response = yield call(getGmapKeyAPi)
    yield put(getMapPublicKeySuccess(response))
  } catch (error) {
    yield put(getMapPublicKeyFail(error))
  }
}
function* searchPlaces({ payload }) {
  try {
    const response = yield call(getSearchLocationAPi, payload)
    yield put(searchPlacesSuccess(response))
  } catch (error) {
    yield put(searchPlacesFail(error))
  }
}

function* settingsSaga() {
  yield takeEvery(GET_SETTINGS, fetchSettings)
  yield takeEvery(UPDATE_SETTINGS, onUpdateSettings)
  yield takeEvery(GET_GMAP_PKEY, fetchGmapKey)
  yield takeEvery(SEARCH_LOCATION, searchPlaces)
}

export default settingsSaga
