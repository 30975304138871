import { get, post } from "helpers/api_helper"
import { take } from "lodash"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  getPromoterDashbordPageviewSuccess,
  getPromoterDashboardPageviewFail,
  getPromoterDashboardEarningsSuccess,
  getPromoterDashboardEaningsFail,
  getPromoterProductSuccess,
  getPromoterProductFail,
  getPromoterProductDetailsFail,
  getPromoterProductDetailsSuccess,
  getAllPromoterOrderSuccess,
  getAllPromoterOrderFail,
  createPromoterPublicFormSuccess,
  createPromoterPublicFormFail,
  getCountryCode,
  getCountryCodeSuccess,
  getCountryCodeFail,
} from "./actions"
import {
  PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS,
  GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS,
  GET_PROMOTER_PRODUCT,
  GET_PROMOTER_PRODUCT_DETAILS,
  GET_ALL_PROMOTER_ORDER,
  GET_PROMOTER_ORDER_DETAILS,
  CREATE_PROMOTER_PUBLIC_FORM,
  GET_COUNTRY_CODE,
} from "./actionTypes"
import {
  getPromoterDetailsFail,
  getPromoterDetailsSuccess,
} from "store/actions"

//Promoter Dashboard page view

function promoterdashboardPageviewApi(page) {
  return get(`/page-view/promoter/all?page=${page ? page : 1}`)
}
// Promoter Dashbord earnings

function promoterDashboardEarningsApi(page) {
  return get(`/earning/promoter/all?page=${page ? page : 1}`)
}

// promoter product

function promoterProductApi({ search, page }) {
  if (search) {
    return get(`/product/promoter/all?search=${search}`)
  } else {
    return get(`/product/promoter/all?page=${page ? page : 1}`)
  }
}

//promoter product details

function productDetailsApi(productId) {
  return get(`/product/promoter/single/${productId}`)
}

const countryCodeApi = ({ searchText, page, limit }) => {
  return get(
    `/static/country/all?search=${searchText ? searchText : ""}&page=${
      page ? page : 1
    }&limit=${limit ? limit : ""}`
  )
}

// promoter order list api

function promoterOrderListApi() {
  return get(`/order/promoter/all`)
}

function promoterOrderDetailsApi({ promoterId }) {
  return get(`/order/promoter/all?promoter=${promoterId}`)
}

// promoter public form

function createPromoterPublicFormApi({ publicPromoter }) {
  console.log(publicPromoter)
  return post(`/promoter/form/new`, publicPromoter)
}

// promoter dashbord
// page view

function* promoterDashboardPageView({ payload }) {
  try {
    const res = yield call(promoterdashboardPageviewApi, payload)
    yield put(getPromoterDashbordPageviewSuccess(res))
  } catch (error) {
    yield put(getPromoterDashboardPageviewFail(error))
  }
}

// earnings

function* promoterDashboardEarnings({ payload }) {
  try {
    const response = yield call(promoterDashboardEarningsApi, payload)
    yield put(getPromoterDashboardEarningsSuccess(response))
  } catch (error) {
    yield put(getPromoterDashboardEaningsFail(error))
  }
}

// promoter product

function* promoterProduct({ payload }) {
  try {
    const response = yield call(promoterProductApi, payload)
    yield put(getPromoterProductSuccess(response))
  } catch (error) {
    yield put(getPromoterProductFail(error))
  }
}
// promoter product details

function* fetchPromoterProductDetails({ payload }) {
  try {
    const response = yield call(productDetailsApi, payload)
    yield put(getPromoterProductDetailsSuccess(response))
  } catch (error) {
    yield put(getPromoterProductDetailsFail(error))
  }
}

//  promoter public form

function* createPromoterPublicForm({ payload }) {
  try {
    const response = yield call(createPromoterPublicFormApi, payload)
    if (response?._id) {
      yield put(createPromoterPublicFormSuccess(response))
      if (payload?.successFunc) {
        payload.successFunc()
      }
    }
  } catch (error) {
    console.log(error)
    yield put(createPromoterPublicFormFail(error.response))
  }
}

function* fetchCountryCode({ payload }) {
  try {
    const response = yield call(countryCodeApi, payload)
    yield put(getCountryCodeSuccess(response))
  } catch (error) {
    yield put(getCountryCodeFail(error))
  }
}

function* promoterSaga() {
  // promoter dashboard
  // page view
  yield takeEvery(
    PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS,
    promoterDashboardPageView
  )
  // earnings
  yield takeEvery(
    GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS,
    promoterDashboardEarnings
  )
  // promoter product
  yield takeEvery(GET_PROMOTER_PRODUCT, promoterProduct)

  // promoter product details
  yield takeEvery(GET_PROMOTER_PRODUCT_DETAILS, fetchPromoterProductDetails)

  yield takeEvery(CREATE_PROMOTER_PUBLIC_FORM, createPromoterPublicForm)
  yield takeEvery(GET_COUNTRY_CODE, fetchCountryCode)
}
export default promoterSaga
