import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import productSaga from "./products/saga"
import shopProductSaga from "./shopProducts/saga"
import shopSaga from "./shops/saga"
import storeSaga from "./stores/saga"
import categorySaga from "./category/saga"
import userSaga from "./users/saga"
import orderSaga from "./orders/saga"
import settingsSaga from "./settings/saga"
import notificationsSaga from "./notification/saga"
import revenuesSaga from "./revenues/saga"
import bannerSaga from "./banner/saga"
import dashboardSaga from "./dashboard/saga"
import classificationsSaga from "./classifications/saga"
import collectionsSaga from "./collections/saga"
import groupsSaga from "./groups/saga"
import couponsSaga from "./coupon/saga"
import areaSaga from "./area_list/saga"
import pagesSaga from "./productPages/saga"
import performanceSaga from "./performance/saga"
import promoterSaga from "./promoter/saga"
import blogSaga from "./blogs/saga"
import forgetPasswordSaga from "./auth/forgetpwd/saga"
import locationSaga from "./location/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(forgetPasswordSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(productSaga),
    fork(shopProductSaga),
    fork(shopSaga),
    fork(storeSaga),

    fork(categorySaga),
    fork(bannerSaga),
    fork(classificationsSaga),
    fork(collectionsSaga),
    fork(groupsSaga),
    fork(pagesSaga),
    fork(couponsSaga),
    fork(areaSaga),

    fork(userSaga),
    fork(orderSaga),
    fork(settingsSaga),
    fork(notificationsSaga),
    fork(revenuesSaga),
    fork(dashboardSaga),

    fork(performanceSaga),
    fork(promoterSaga),
    fork(blogSaga),
    fork(locationSaga),
  ])
}
