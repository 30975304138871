import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from "./actionTypes"

const initialState = {
  loading: false,
  forgetSuccessMsg: null,
  forgetError: null,
  error: {},
  password: {},

  resetPass: "",
  resetPassLoad: true,
}

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      return {
        ...state,
        loading: true,
        forgetSuccessMsg: null,
        forgetError: null,
      }
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetSuccessMsg: action.payload,
        loading: false,
      }
    case FORGET_PASSWORD_ERROR:
      return { ...state, forgetError: action.payload, loading: false }

    case CHANGE_PASSWORD:
      state = {
        ...state,
        resetPassLoad: true,
      }
      break
    case CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        resetPassLoad: true,
        resetPass: action.payload,
      }
      break
    case CHANGE_PASSWORD_ERROR:
      state = { ...state, forgetError: action.payload }
      break

    case RESET_PASSWORD:
      return {
        ...state,
        loading: true,
      }

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        password: action.payload,
      }

    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      state = { ...state }
      break
  }
  return state
}

export default forgetPassword
