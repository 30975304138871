import {
  CREATE_DISTRICT,
  CREATE_DISTRICT_SUCCESS,
  CREATE_DISTRICT_FAIL,
  GET_ALL_DISTRICTS,
  GET_ALL_DISTRICTS_SUCCESS,
  GET_ALL_DISTRICTS_FAIL,
  UPDATE_DISTRICT_SUCCESS,
  UPDATE_DISTRICT_FAIL,
  UPDATE_DISTRICT,
  DELETE_DISTRICT,
  DELETE_DISTRICT_SUCCESS,
  DELETE_DISTRICT_FAIL,
  CREATE_STATE,
  CREATE_STATE_SUCCESS,
  CREATE_STATE_FAIL,
  GET_ALL_STATES,
  GET_ALL_STATES_SUCCESS,
  GET_ALL_STATES_FAIL,
  UPDATE_STATE_SUCCESS,
  UPDATE_STATE_FAIL,
  UPDATE_STATE,
  DELETE_STATE,
  DELETE_STATE_SUCCESS,
  DELETE_STATE_FAIL,
  CREATE_TALUK,
  CREATE_TALUK_SUCCESS,
  CREATE_TALUK_FAIL,
  GET_ALL_TALUKS,
  GET_ALL_TALUKS_SUCCESS,
  GET_ALL_TALUKS_FAIL,
  UPDATE_TALUK_SUCCESS,
  UPDATE_TALUK_FAIL,
  UPDATE_TALUK,
  DELETE_TALUK,
  DELETE_TALUK_SUCCESS,
  DELETE_TALUK_FAIL,
  CREATE_LSG,
  CREATE_LSG_SUCCESS,
  CREATE_LSG_FAIL,
  GET_ALL_LSGS,
  GET_ALL_LSGS_SUCCESS,
  GET_ALL_LSGS_FAIL,
  UPDATE_LSG_SUCCESS,
  UPDATE_LSG_FAIL,
  UPDATE_LSG,
  DELETE_LSG,
  DELETE_LSG_SUCCESS,
  DELETE_LSG_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  districts: [],
  districtDetails: {},

  states: [],
  stateDetails: {},

  taluks: [],
  talukDetails: {},

  lsgs: [],
  lgsDetails: {},

  error: {},
  loading: false,
}

const Location = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_DISTRICT:
    case GET_ALL_DISTRICTS:
    case DELETE_DISTRICT:
    case UPDATE_DISTRICT:

    case CREATE_STATE:
    case GET_ALL_STATES:
    case DELETE_STATE:
    case UPDATE_STATE:

    case CREATE_TALUK:
    case GET_ALL_TALUKS:
    case DELETE_TALUK:
    case UPDATE_TALUK:

    case CREATE_LSG:
    case GET_ALL_LSGS:
    case DELETE_LSG:
    case UPDATE_LSG:
      return {
        ...state,
        loading: true,
      }

    case CREATE_DISTRICT_FAIL:
    case GET_ALL_DISTRICTS_FAIL:
    case UPDATE_DISTRICT_FAIL:
    case DELETE_DISTRICT_FAIL:

    case CREATE_STATE_FAIL:
    case GET_ALL_STATES_FAIL:
    case UPDATE_STATE_FAIL:
    case DELETE_STATE_FAIL:

    case CREATE_TALUK_FAIL:
    case GET_ALL_TALUKS_FAIL:
    case UPDATE_TALUK_FAIL:
    case DELETE_TALUK_FAIL:

    case CREATE_LSG_FAIL:
    case GET_ALL_LSGS_FAIL:
    case UPDATE_LSG_FAIL:
    case DELETE_LSG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // ..............................

    // create  district
    case CREATE_DISTRICT_SUCCESS:
      return {
        ...state,
        districts: {
          ...state.districts,
          districts: [action.payload, ...state.districts.districts],
        },
        error: {},
        loading: false,
      }
    // ALL district
    case GET_ALL_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: action.payload,
        error: {},
        loading: false,
      }
    // update district
    case UPDATE_DISTRICT_SUCCESS:
      return {
        ...state,
        error: {},
        districts: {
          ...state.districts,
          districts: state.districts?.districts?.map(item =>
            item._id === action.payload._id ? { item, ...action.payload } : item
          ),
        },
        loading: false,
      }
    // delete district
    case DELETE_DISTRICT_SUCCESS:
      return {
        ...state,
        districts: {
          ...state.districts,
          districts: state.districts?.districts?.filter(
            i => i._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }
    // create  state
    case CREATE_STATE_SUCCESS:
      return {
        ...state,
        states: {
          ...state.states,
          states: [action.payload, ...state.states.states],
        },
        error: {},
        loading: false,
      }
    // ALL sate
    case GET_ALL_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload,
        error: {},
        loading: false,
      }
    // update
    case UPDATE_STATE_SUCCESS:
      return {
        ...state,
        error: {},
        states: {
          ...state.states,
          states: state.states?.states?.map(item =>
            item._id === action.payload._id ? { item, ...action.payload } : item
          ),
        },
        loading: false,
      }
    // delete
    case DELETE_STATE_SUCCESS:
      return {
        ...state,
        states: {
          ...state.states,
          states: state.states?.states?.filter(
            i => i._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }
    // create  TALUK
    case CREATE_TALUK_SUCCESS:
      return {
        ...state,
        taluks: {
          ...state.taluks,
          taluks: [action.payload, ...state.taluks.taluks],
        },

        error: {},
        loading: false,
      }
    // ALL TALUK
    case GET_ALL_TALUKS_SUCCESS:
      return {
        ...state,
        taluks: action.payload,
        error: {},
        loading: false,
      }
    // update taluks
    case UPDATE_TALUK_SUCCESS:
      return {
        ...state,
        error: {},
        taluks: {
          ...state.taluks,
          taluks: state.taluks?.taluks?.map(item =>
            item._id === action.payload._id ? { item, ...action.payload } : item
          ),
        },
        loading: false,
      }
    // delete
    case DELETE_TALUK_SUCCESS:
      return {
        ...state,
        taluks: {
          ...state.taluks,
          taluks: state.taluks?.taluks?.filter(
            i => i._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }
    // create  LSG
    case CREATE_LSG_SUCCESS:
      return {
        ...state,
        lsgs: {
          ...state.lsgs,
          lsgs: [action.payload, ...state.lsgs.lsgs],
        },
        error: {},
        loading: false,
      }
    // ALL lsgs
    case GET_ALL_LSGS_SUCCESS:
      return {
        ...state,
        lsgs: action.payload,
        error: {},
        loading: false,
      }
    // update
    case UPDATE_LSG_SUCCESS:
      return {
        ...state,
        error: {},
        lsgs: {
          ...state.lsgs,
          lsgs: state.lsgs?.lsgs?.map(item =>
            item._id === action.payload._id ? { item, ...action.payload } : item
          ),
        },
        loading: false,
      }
    // delete lsgs
    case DELETE_LSG_SUCCESS:
      return {
        ...state,
        lsgs: {
          ...state.lsgs,
          lsgs: state.lsgs?.lsgs?.filter(i => i._id !== action.payload._id),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default Location
