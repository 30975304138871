import { takeEvery, put, call } from "redux-saga/effects"

// Login Redux States
import { CHANGE_PASSWORD, FORGET_PASSWORD, RESET_PASSWORD } from "./actionTypes"
import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  updatePasswordSuccess,
  updatePasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from "./actions"
import { post } from "helpers/api_helper"
import { Notification, errorNotification } from "components/Common/Notification"

function forgetPasswordApi({ user }) {
  console.log(user)
  return post("/forgot", user)
}

function* onForgetPassword({ payload }) {
  try {
    const response = yield call(forgetPasswordApi, payload)
    if (response) {
      // localStorage.setItem("token", response?.token)
      yield put(userForgetPasswordSuccess(response))
      Notification({
        type: "success",
        message: "Email Send Successfully!",
        title: "",
      })
      // if (payload?.onCloseClick()) {
      //   payload?.onCloseClick()
      // }
    }
  } catch (error) {
    console.log(error)
    yield put(userForgetPasswordError(error))
    errorNotification()
  }
}

function changePasswordApi({ passwords }) {
  return post(`/password/update`, passwords)
}

function* changePassword({ payload }) {
  try {
    const response = yield call(changePasswordApi, payload)
    if (response?.token) {
      localStorage.setItem("token", response?.token)
      yield put(updatePasswordSuccess(response))
      Notification({
        type: "success",
        message: "Password Changed Successfully!",
        title: "",
      })
      if (payload?.onCloseClick()) {
        payload?.onCloseClick()
      }
    }
  } catch (error) {
    yield put(updatePasswordError(error?.response?.data))
    errorNotification()
  }
}

function resetPasswordApi({ value, resetId }) {
  return post(`/password/reset/${resetId}`, value)
}

function* onResetPassword({ payload }) {
  try {
    const response = yield call(resetPasswordApi, payload)
    if (response) {
      yield put(resetPasswordSuccess(response))
      Notification({
        type: "success",
        message: "Password Changed Successfully!",
        title: "",
      })

      if (payload?.history) {
        payload?.history.push("/login")
      }
    }
  } catch (error) {
    yield put(resetPasswordError(error?.response?.data))
    errorNotification()
  }
}

function* forgetPasswordSaga() {
  // yield all([fork(watchUserPasswordForget)])
  yield takeEvery(CHANGE_PASSWORD, changePassword)
  yield takeEvery(FORGET_PASSWORD, onForgetPassword)
  yield takeEvery(RESET_PASSWORD, onResetPassword)
}

export default forgetPasswordSaga
