import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_CHARTS_DATA,
  GET_DASHBOARD_DATA,
  GET_PROMOTER_DASHBOARD_DATA,
  GET_PROMOTER_PROFILE_DETAILS,
  PROMOTER_PROFILE_UPDATE,
} from "./actionTypes"
import {
  apiSuccess,
  apiFail,
  getDashboardDataSuccess,
  getDashboardDataFail,
  getPromoterProfileDetailsSuccess,
  getPromoterProfileDetailsFail,
  promoterProfileUpdateSuccess,
  promoterProfileUpdateFail,
} from "./actions"

import { ApiPut, get } from "helpers/api_helper"
import {
  getPromoterDashboardDataFail,
  getPromoterDashboardDataSuccess,
} from "store/actions"
import { Notification } from "components/Common/Notification"

function getDashboardDataAPi() {
  return get("/static/dashboard/data")
}

// promoter profile api
function promoterProfileApi() {
  return get(`/promoter`)
}

// promoter dashboard api

function promoterDashboardDataApi() {
  return get(`/static/promoter/dashboard/data`)
}
function updatePromoterProfileApi(promoterData) {
  return ApiPut(`/promoter`, promoterData)
}

function* getChartsData({ payload: periodType }) {
  try {
    var response
    yield put(apiSuccess(GET_CHARTS_DATA, response))
  } catch (error) {
    yield put(apiFail(GET_CHARTS_DATA, error))
  }
}

function* fetchDashboardData() {
  try {
    const response = yield call(getDashboardDataAPi)
    yield put(getDashboardDataSuccess(response))
  } catch (error) {
    yield put(getDashboardDataFail(error))
  }
}
function* promoterProfile({ payload }) {
  try {
    const response = yield call(promoterProfileApi, payload)
    yield put(getPromoterProfileDetailsSuccess(response))
  } catch (error) {
    yield put(getPromoterProfileDetailsFail(error))
  }
}

//promoter dashboard data

function* promoterDashboardData({ payload }) {
  try {
    const response = yield call(promoterDashboardDataApi, payload)
    yield put(getPromoterDashboardDataSuccess(response))
  } catch (error) {
    yield put(getPromoterDashboardDataFail(error))
  }
}

function* updatePromoterProfile({ payload }) {
  try {
    const response = yield call(updatePromoterProfileApi, payload?.promoterData)
    yield put(promoterProfileUpdateSuccess(response))
    Notification({
      type: "success",
      message: "Promoter Updated Successfully!",
      title: "",
    })
    if (payload?.history) {
      payload?.history.push("/promoter-profile")
    }
  } catch (error) {
    yield put(promoterProfileUpdateFail(error?.response?.data))
  }
}

export function* watchGetChartsData() {
  yield takeEvery(GET_CHARTS_DATA, getChartsData)
}

function* dashboardSaga() {
  yield all([fork(watchGetChartsData)])

  yield takeEvery(GET_DASHBOARD_DATA, fetchDashboardData)
  yield takeEvery(GET_PROMOTER_PROFILE_DETAILS, promoterProfile)
  yield takeEvery(GET_PROMOTER_DASHBOARD_DATA, promoterDashboardData)
  yield takeEvery(PROMOTER_PROFILE_UPDATE, updatePromoterProfile)
}

export default dashboardSaga
