import { AvField, AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import React from "react"
import { useDispatch } from "react-redux"
import { CardTitle, Col, Modal, ModalBody, Row } from "reactstrap"
import { createState, updateState } from "store/actions"

const StateModal = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()

  console.log(updateData)
  const handleValidSubmit = (e, v) => {
    if (updateData?._id) {
      dispatch(updateState(updateData?._id, v, onCloseClick))
    } else {
      dispatch(createState(v, onCloseClick))
    }
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
    >
      <ModalBody style={deleteModalBodyStyle} className="p-4">
        <CardTitle>{updateData?._id ? "Update" : "Add New"} State</CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <Col lg={12}>
              <AvField
                name="name"
                type="text"
                placeholder="State name"
                value={updateData?.name}
                className="mb-3"
              />
            </Col>
          </Row>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-danger ms-2"
              onClick={onCloseClick}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-success ms-2">
              Save
            </button>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

StateModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default StateModal

const deleteModalStyle = {
  width: 300,
  minWidth: 150,
}
const deleteModalBodyStyle = {
  minHeight: 100,
}
