import { AvField } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Label, Row } from "reactstrap"
import {
  checkPromoterId,
  getAllDistricts,
  getAllLsgs,
  getAllStates,
  getAllTaluks,
} from "store/actions"
import PropTypes from "prop-types"
import Select from "react-select"
import MyPhoneInput from "components/Common/MyPhoneInput"
import { debounce } from "lodash"

function Form({ updateData, isUpdate, formData }) {
  const {
    setCountryCode,
    countryCode,
    setTalukId,
    setLsgId,
    setDistrictId,
    setStateId,
    talukId,
    districtId,
    stateId,
  } = formData

  const dispatch = useDispatch()

  const [checkPromoter, setCheckPromoter] = useState()
  const [taluk, setTaluk] = useState("Select taluk")
  const [searchTaluk, setSearchTaluk] = useState("")
  const [lsg, setLsg] = useState("Select lsg")
  const [searchLsg, setSearchLsg] = useState("")
  const [district, setDistrict] = useState("Select district")
  const [searchDistrict, setSearchDistrict] = useState("")
  const [state, setState] = useState("Select state")
  const [searchState, setSearchState] = useState("")

  const { promoterIdCheck, taluks, lsgs, districts, states } = useSelector(
    state => ({
      promoterIdCheck: state?.Users?.promoterIdCheck?.response,
      taluks: state.Location.taluks,
      lsgs: state.Location.lsgs,
      districts: state.Location.districts,
      states: state.Location.states,
    })
  )

  const role = sessionStorage.getItem("role")

  const debounceCategorySearch = debounce(value => setSearchTaluk(value), 600)
  const debounceLsgSearch = debounce(value => setSearchLsg(value), 600)
  const debounceDistrictSearch = debounce(
    value => setSearchDistrict(value),
    600
  )
  const debounceStateSearch = debounce(value => setSearchState(value), 600)

  const handleEntersTaluk = textEntered => {
    debounceCategorySearch(textEntered)
  }
  const handleEntersLsg = textEntered => {
    debounceLsgSearch(textEntered)
  }
  const handleEntersDistrict = textEntered => {
    debounceDistrictSearch(textEntered)
  }
  const handleEntersState = textEntered => {
    debounceStateSearch(textEntered)
  }

  function handlerFinalValueTaluk(event) {
    setTaluk(event.label)
    setTalukId(event.value)
  }
  function handlerFinalValueLsg(event) {
    setLsg(event.label)
    setLsgId(event.value)
  }
  function handlerFinalValueDistrict(event) {
    setDistrict(event.label)
    setDistrictId(event.value)
  }
  function handlerFinalValueState(event) {
    setState(event.label)
    setStateId(event.value)
  }

  const talukOptions = [
    {
      label: "All Taluks",
      value: "",
    },
    {
      options:
        taluks &&
        taluks?.total >= 1 &&
        taluks?.taluks?.map((result, index) => ({
          key: index,
          label: result?.name,
          value: result._id,
        })),
    },
  ]
  const lsgOptions = [
    {
      label: "All Lsgs",
      value: "",
    },
    {
      options:
        lsgs &&
        lsgs?.total >= 1 &&
        lsgs?.lsgs?.map((result, index) => ({
          key: index,
          label: result?.name,
          value: result._id,
        })),
    },
  ]
  const districtOptions = [
    {
      label: "All Districts",
      value: "",
    },
    {
      options:
        districts &&
        districts?.total >= 1 &&
        districts?.districts?.map((result, index) => ({
          key: index,
          label: result?.name,
          value: result._id,
        })),
    },
  ]
  const stateOptions = [
    {
      label: "All States",
      value: "",
    },
    {
      options:
        states &&
        states?.total >= 1 &&
        states?.states?.map((result, index) => ({
          key: index,
          label: result?.name,
          value: result._id,
        })),
    },
  ]

  useEffect(() => {
    dispatch(getAllLsgs(searchLsg, talukId))
  }, [dispatch, searchLsg, talukId])

  useEffect(() => {
    dispatch(getAllTaluks(searchTaluk, districtId))
  }, [dispatch, searchTaluk, districtId])

  useEffect(() => {
    dispatch(getAllDistricts(searchDistrict, stateId))
  }, [dispatch, searchDistrict, stateId])

  useEffect(() => {
    dispatch(getAllStates(searchState))
  }, [dispatch, searchState])

  useEffect(() => {
    if (updateData) {
      setStateId(updateData?.state?._id)
      setState(updateData?.state?.name)
      setDistrictId(updateData?.district?._id)
      setDistrict(updateData?.district?.name)
      setTalukId(updateData?.taluk?._id)
      setTaluk(updateData?.taluk?.name)
      setLsgId(updateData?.lsg?._id)
      setLsg(updateData?.lsg?.name)
    }
  }, [updateData])

  return (
    <>
      <Row>
        <Col className="col-12 d-flex flex-wrap">
          <div className="col-6 mb-4 px-3">
            <AvField
              label={
                <>
                  Name<sup>*</sup>
                </>
              }
              name="name"
              required
              type="text"
              value={updateData?.name ?? ""}
            />
          </div>
          <div className="col-6 mb-4 px-3 ">
            <label>
              Whatsapp Number<sup>*</sup>
            </label>
            <div className="d-flex align-items-center">
              <MyPhoneInput
                setCountryCode={setCountryCode}
                countryCode={countryCode}
              />
              <AvField
                className="ms-2"
                name="phone"
                placeholder="Phone"
                type="number"
                value={updateData?.phone ?? ""}
              />
            </div>
          </div>
          {/* <div className="col-6 mb-4 px-3">
            <AvField
              label={
                <>
                  Phone<sup>*</sup>
                </>
              }
              name="phone"
              maxLength="10"
              required
              type="number"
              value={updateData?.phone ?? ""}
            />
          </div> */}
          {isUpdate ? (
            <div className="col-12 mb-4 px-3">
              <AvField
                name="email"
                required
                readOnly={updateData?.email ? true : false}
                label={
                  <>
                    Email<sup>*</sup>
                  </>
                }
                type="email"
                value={updateData?.email ?? ""}
              />
            </div>
          ) : (
            <div className="col-6 mb-4 px-3">
              <AvField
                name="email"
                required
                label={
                  <>
                    Email<sup>*</sup>
                  </>
                }
                type="email"
                value={updateData?.email ?? ""}
              />
            </div>
          )}

          {isUpdate ? (
            ""
          ) : (
            <div className="col-6 mb-4 px-3">
              <AvField
                name="password"
                required
                value={updateData?.password ?? ""}
                label={
                  <>
                    Password<sup>*</sup>
                  </>
                }
                type="password"
              />
            </div>
          )}

          <div className="col-12 mb-4 px-3">
            <AvField
              name="address"
              label="Address"
              value={updateData?.address ?? ""}
              type="textarea"
            />
          </div>
          <div className="col-6 mb-4 px-3">
            <AvField
              name="country"
              label="Country"
              value={updateData?.country ?? ""}
              type="text"
            />
          </div>
          <div className="col-6 mb-4 px-3">
            <Label>State</Label>
            <Select
              onInputChange={handleEntersState}
              value={state}
              placeholder={state}
              onChange={handlerFinalValueState}
              options={stateOptions}
              classNamePrefix="select2-selection"
              // isLoading={catLoading}
            />
          </div>
          <div className="col-6 mb-4 px-3">
            <Label>District</Label>
            <Select
              onInputChange={handleEntersDistrict}
              value={district}
              placeholder={district}
              onChange={handlerFinalValueDistrict}
              options={districtOptions}
              classNamePrefix="select2-selection"
              // isLoading={catLoading}
            />
          </div>
          <div className="col-6 mb-4 px-3">
            <Label>Taluk</Label>
            <Select
              onInputChange={handleEntersTaluk}
              value={taluk}
              placeholder={taluk}
              onChange={handlerFinalValueTaluk}
              options={talukOptions}
              classNamePrefix="select2-selection"
              // isLoading={catLoading}
            />
          </div>
          <div className="col-6 mb-4 px-3">
            <Label>Lsg</Label>
            <Select
              onInputChange={handleEntersLsg}
              value={lsg}
              placeholder={lsg}
              onChange={handlerFinalValueLsg}
              options={lsgOptions}
              classNamePrefix="select2-selection"
              // isLoading={catLoading}
            />
          </div>
          <div className="col-6 mb-4 px-3">
            <AvField
              name="city"
              label="City"
              value={updateData?.city ?? ""}
              type="text"
            />
          </div>
          <div className="col-6 mb-4 px-3">
            <AvField
              name="place"
              label="Place"
              value={updateData?.place ?? ""}
              type="text"
            />
          </div>
          <div className="col-6 mb-4 px-3">
            <AvField
              name="pincode"
              label="Pincode"
              value={updateData?.pincode ?? ""}
              type="number"
            />
          </div>
          {updateData?.ref_id || role === "admin" ? (
            <div className="col-6 mb-4 px-3">
              <AvField
                name="ref_id"
                required
                value={updateData?.ref_id ?? ""}
                readOnly={updateData?.ref_id ? true : false}
                label={
                  <>
                    Promoter Id<sup>*</sup>
                  </>
                }
                type="text"
                onChange={e => {
                  setCheckPromoter(e.target.value)
                  dispatch(checkPromoterId(e.target.value))
                }}
              />

              {checkPromoter && (
                <>
                  {promoterIdCheck === true ? (
                    <h6 className="pt-1" style={{ color: "green" }}>
                      {" "}
                      Available
                    </h6>
                  ) : (
                    <h6 className="pt-1" style={{ color: "red" }}>
                      Not Available{" "}
                    </h6>
                  )}
                </>
              )}
            </div>
          ) : (
            ""
          )}

          {role === "admin" && (
            <div className="col-6 mb-4 px-3">
              <AvField
                required
                name="promoterPercentage"
                value={updateData?.promoterPercentage ?? ""}
                readOnly={updateData?.ref_id ? true : false}
                label={
                  <>
                    Promoter Percentage<sup>*</sup>
                  </>
                }
                type="number"
              />
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}

Form.propTypes = {
  updateData: PropTypes.object,
  isUpdate: PropTypes.bool,
  formData: PropTypes.object,
}
export default Form
