import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Notifications
import Notifications from "pages/Notification/index"

//ZestonProduct
import Product from "pages/Product/index"
import DeletedProduct from "pages/Product/Deleted/index"
import ProductDetails from "pages/Product/SingleView/index"
import UpdateProduct from "pages/Product/Crud/Update/Update"
import CreateProduct from "pages/Product/Crud/Create/Create"

//ShopProduct
import ShopProduct from "pages/ShopProduct/index"
import DeletedShopProduct from "pages/ShopProduct/Deleted/index"
import ShopProductDetails from "pages/ShopProduct/SingleView/index"
import UpdateShopProduct from "pages/ShopProduct/Crud/Update/Update"
import CreateShopProduct from "pages/ShopProduct/Crud/Create/Create"

//Store
import UpdateStore from "pages/Store/Crud/Update/Update"
import Store from "pages/Store/index"
import StoreDetails from "pages/Store/SingleView/index"
import QuarentinedStore from "pages/Store/QuarantinedStores/index"
import UnapprovedStore from "pages/Store/UnapprovedStores/index"
import CreateStore from "pages/Store/Crud/Create/Create"

//Shop
import UpdateShop from "pages/Shop/Crud/Update/Update"
import Shop from "pages/Shop/index"
import ShopDetails from "pages/Shop/SingleView/index"
import QuarentinedShop from "pages/Shop/QuarantinedShops/index"
import UnapprovedShop from "pages/Shop/UnapprovedShops/index"
import CreateShop from "pages/Shop/Crud/Create/Create"

//Banner
import Banners from "pages/Banner/index"
import UpdateBanner from "pages/Banner/Crud/Update"
import CreateBanner from "pages/Banner/Crud/Create"

//User
import User from "pages/User/index"
import UserDetails from "pages/User/SingleView/index"
import UpdateUser from "pages/User/Crud/Update/Update"

//Order
import Orders from "pages/Order/index"
import OrderDetails from "pages/Order/SingleView/index"
import StarredOrders from "pages/Order/StarredOrder/index"
import CancelledOrders from "pages/Order/CancelledOrder/index"
import ReturnedOrders from "pages/Order/ReturnedOrder/index"

// Settings
import Banner from "pages/Settings/index"
import UpdateSettings from "pages/Settings/Crud/Update/Update"

//Revenue
import Revenues from "pages/Revenue/index"
import RevenueDetail from "pages/Revenue/shopDetail/index"

//Errors
//404
import Pages404 from "pages/error/pages-404"
import Classification from "pages/Classifications/index"
import Collections from "pages/collections"
import CrateCollections from "pages/collections/Crud/Create"
import UpdateCollections from "pages/collections/Crud/Update"
import Groups from "pages/groups-home"
import CrateGroups from "pages/groups-home/Crud/Create"
import UpdateGroups from "pages/groups-home/Crud/Update"
import Coupons from "pages/coupon"
import CreateCoupon from "pages/coupon/Crud/Create"
import UpdateCoupons from "pages/coupon/Crud/Update"
import AreaList from "pages/Area"
import CreateCategory from "pages/Classifications/Create/Create"
import UpdateCategory from "pages/Classifications/Update/Update"
import UpdateProductStock from "pages/Product/stockUpdate/Update"
import CreateAdminOrder from "pages/Order/Crud/Create"
import ProductPages from "pages/product-pages"
import CratePages from "pages/product-pages/Crud/Create"
import UpdatePages from "pages/product-pages/Crud/Update"
import CreatePromoter from "pages/Promoter/Crud/Create"
import UpdatePromoter from "pages/Promoter/Crud/Update"
import PromoterDetails from "pages/Promoter/SingleView"
import Promoters from "pages/Promoter"
import AllPageView from "pages/Promoter/Sub-Contents/allPageView"
import AllEarnings from "pages/Promoter/Sub-Contents/allEarnings"
import FormattedEarnings from "pages/Promoter/Sub-Contents/FormattedEarnings"

import ProductPerformance from "pages/Performance"
import PageViews from "pages/Promoter/Dashboard/PageView"
import promoterEarnings from "pages/Promoter/Dashboard/Earnings"
import Products from "pages/Promoter/Dashboard/Products"
import promoterProductDetails from "pages/Promoter/Dashboard/ProductDetails"
import Reviews from "pages/Reviews"
import Blogs from "pages/Blogs"
import Transactions from "pages/Transactions"
import PromoterOrder from "pages/Promoter/Dashboard/Order"
import PromoterForm from "pages/Promoter/PublicForm/Form"
import ResetPassword from "pages/Authentication/NewPassword"
import Districts from "pages/District"
import Lsgs from "pages/Lsg"
import Taluks from "pages/Taluk"
import States from "pages/State"

const commonRoute = [
  { path: "/dashboard", component: Dashboard },

  { path: "/logout", component: Logout },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

const superAdminRoutes = [
  // products
  { path: "/products", component: Product },
  { path: "/products/deleted", component: DeletedProduct },
  { path: "/product/:id", component: ProductDetails },
  { path: "/product/create/new", component: CreateProduct },
  { path: "/product/update/:id", component: UpdateProduct },
  { path: "/stockUpdate", component: UpdateProductStock },

  // shopProducts
  // { path: "/shopproducts", component: ShopProduct },
  // { path: "/shopproducts/deleted", component: DeletedShopProduct },
  // { path: "/shopproduct/:id", component: ShopProductDetails },
  // { path: "/shopproduct/create/new", component: CreateShopProduct },
  // { path: "/shopproduct/update/:id", component: UpdateShopProduct },

  //stores
  // { path: "/stores", component: Store },
  // { path: "/stores/quarentined", component: QuarentinedStore },
  // { path: "/stores/unapproved", component: UnapprovedStore },
  // { path: "/store/:id", component: StoreDetails },
  // { path: "/store/create/new", component: CreateStore },
  // { path: "/store/update/:id", component: UpdateStore },

  // //shops
  // { path: "/shops", component: Shop },
  // { path: "/shops/quarentined", component: QuarentinedShop },
  // { path: "/shops/unapproved", component: UnapprovedShop },
  // { path: "/shop/:id", component: ShopDetails },
  // { path: "/shop/create/new", component: CreateShop },
  // { path: "/shop/update/:id", component: UpdateShop },

  //Classifications
  { path: "/classifications", component: Classification },
  { path: "/category/create/new", component: CreateCategory },
  { path: "/category/update/:id", component: UpdateCategory },

  //collections
  { path: "/collections", component: Collections },
  { path: "/collections/create/new", component: CrateCollections },
  { path: "/collections/update/:id", component: UpdateCollections },

  //groups
  { path: "/groups", component: Groups },
  { path: "/groups/create/new", component: CrateGroups },
  { path: "/groups/update/:id", component: UpdateGroups },

  //pages
  { path: "/pages/all", component: ProductPages },
  { path: "/pages/product/create", component: CratePages },
  { path: "/pages/product/:id", component: UpdatePages },

  //coupons
  { path: "/coupons", component: Coupons },
  { path: "/coupons/create/new", component: CreateCoupon },
  { path: "/coupons/update/:id", component: UpdateCoupons },

  //area
  { path: "/area", component: AreaList },

  // Users
  { path: "/users", component: User },
  { path: "/users/:id", component: UserDetails },
  { path: "/user/update/:id", component: UpdateUser },

  // Orders
  { path: "/orders", component: Orders },
  { path: "/orders/new", component: CreateAdminOrder },
  { path: "/order/:id", component: OrderDetails },
  { path: "/orders/starred", component: StarredOrders },
  { path: "/orders/cancelled", component: CancelledOrders },
  { path: "/orders/returned", component: ReturnedOrders },

  //Dashboard
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },

  //Notifications
  { path: "/notifications", component: Notifications },

  //Banner
  { path: "/banners", component: Banners },
  { path: "/banner/create/new", component: CreateBanner },
  { path: "/banner/update/:id", component: UpdateBanner },

  //review
  { path: "/reviews", component: Reviews },
  // blog
  { path: "/blogs", component: Blogs },
  // locations
  { path: "/state", component: States },
  { path: "/district", component: Districts },
  { path: "/taluks", component: Taluks },
  { path: "/lsgs", component: Lsgs },

  // transactions

  { path: "/transactions", component: Transactions },

  //Settings
  { path: "/superadmin/settings", component: Banner },
  { path: "/superadmin/settings/:id", component: UpdateSettings },

  //Revenues
  { path: "/revenues", component: Revenues },
  { path: "/revenue/:id", component: RevenueDetail },

  //Performance
  { path: "/performance/product", component: ProductPerformance },

  // promoter
  { path: "/promoters", component: Promoters },
  { path: "/promoter/:id", component: PromoterDetails },
  { path: "/promoter/create/new", component: CreatePromoter },
  { path: "/promoter/update/:id", component: UpdatePromoter },

  { path: "/all-page-view", component: AllPageView },
  { path: "/all-earnings", component: AllEarnings },
  { path: "/formatted-earnings", component: FormattedEarnings },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const promoterRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/promoter-profile", component: PromoterDetails },
  { path: "/promoter/update/:id", component: UpdatePromoter },

  { path: "/promoter-page-view", component: PageViews },
  { path: "/promoter-earnigs", component: promoterEarnings },
  { path: "/promoter/products", component: Products },
  { path: `/promoter/product/:id`, component: promoterProductDetails },
  // { path: "/promoter/order", component: PromoterOrder },
  { path: "/orders", component: Orders },
  { path: "/orders/new", component: CreateAdminOrder },
  { path: "/order/:id", component: OrderDetails },
  { path: "/orders/starred", component: StarredOrders },
  { path: "/orders/cancelled", component: CancelledOrders },
  { path: "/orders/returned", component: ReturnedOrders },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/password/reset/:id", component: ResetPassword },
  { path: "/register", component: Register },
  { path: "/promoter/public", component: PromoterForm },

  { path: "/404", component: Pages404 },
]

export { publicRoutes, superAdminRoutes, promoterRoutes, commonRoute }
