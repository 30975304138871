import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  CREATE_DISTRICT,
  GET_ALL_DISTRICTS,
  UPDATE_DISTRICT,
  DELETE_DISTRICT,
  CREATE_STATE,
  GET_ALL_STATES,
  UPDATE_STATE,
  DELETE_STATE,
  CREATE_TALUK,
  GET_ALL_TALUKS,
  UPDATE_TALUK,
  DELETE_TALUK,
  CREATE_LSG,
  GET_ALL_LSGS,
  UPDATE_LSG,
  DELETE_LSG,
} from "./actionTypes"
import {
  createDistrictFail,
  createDistrictSuccess,
  getAllDistrictsSuccess,
  getAllDistrictsFail,
  updateDistrictSuccess,
  updateDistrictFail,
  deleteDistrictSuccess,
  deleteDistrictFail,
  createStateSuccess,
  createStateFail,
  getAllStatesSuccess,
  getAllStatesFail,
  updateStateSuccess,
  updateStateFail,
  deleteStateSuccess,
  deleteStateFail,
  createTalukSuccess,
  createTalukFail,
  getAllTaluksSuccess,
  getAllTaluksFail,
  updateTalukSuccess,
  updateTalukFail,
  deleteTalukFail,
  deleteTalukSuccess,
  createLsgSuccess,
  createLsgFail,
  getAllLsgsSuccess,
  getAllLsgsFail,
  deleteLsgSuccess,
  deleteLsgFail,
  updateLsgFail,
  updateLsgSuccess,
} from "./actions"
import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"
// create District
const createDistrictApi = ({ district }) => {
  return post("/district/admin/new", district)
}
// all District

function getAllDistrictsAPi({ searchText, stateId, page, limit }) {
  // sort=${sort ? sort : ""}
  return get(
    `/district/all?page=${page ? page : 1}&limit=${limit ? limit : 10}&search=${
      searchText ? searchText : ""
    }&state=${stateId ? stateId : ""}`
  )
}
// update District
const updateDistrictApi = ({ districtId, district }) => {
  return ApiPut(`/district/admin/${districtId}`, district)
}
// delete District
const deleteDistrictApi = districtId => {
  return del(`/district/admin/${districtId}`)
}
// create State
const createStateApi = ({ state }) => {
  return post("/state/admin/new", state)
}
// all State

function getAllStatesAPi({ searchText, page, limit }) {
  // sort=${sort ? sort : ""}
  return get(
    `/state/all?page=${page ? page : 1}&limit=${limit ? limit : 10}&search=${
      searchText ? searchText : ""
    }`
  )
}
// update State
const updateStateApi = ({ stateId, state }) => {
  return ApiPut(`/state/admin/${stateId}`, state)
}
// delete State
const deleteStateApi = stateId => {
  return del(`/state/admin/${stateId}`)
}

// create TALUK
const createTalukApi = ({ taluk }) => {
  return post("/taluk/admin/new", taluk)
}
// all Taluk

function getAllTaluksAPi({ searchText, districtId, page, limit }) {
  // sort=${sort ? sort : ""}
  return get(
    `/taluk/all?page=${page ? page : 1}&district=${
      districtId ? districtId : ""
    }&limit=${limit ? limit : 10}&search=${searchText ? searchText : ""}`
  )
}
// update Taluk
const updateTalukApi = ({ talukId, taluk }) => {
  return ApiPut(`/taluk/admin/${talukId}`, taluk)
}
// delete Taluk
const deleteTalukApi = talukId => {
  return del(`/taluk/admin/${talukId}`)
}

// create LSG
const createLsgApi = ({ lsg }) => {
  return post("lsg/admin/new", lsg)
}
// all Lsg

function getAllLsgsAPi({ searchText, districtId, page, limit }) {
  // sort=${sort ? sort : ""}
  return get(
    `/lsg/all?page=${page ? page : 1}&district=${districtId ? districtId : ""}&limit=${limit ? limit : 10}&search=${searchText ? searchText : ""}`
  )
}
// update Lsg
const updateLsgApi = ({ lsgId, lsg }) => {
  return ApiPut(`/lsg/admin/${lsgId}`, lsg)
}
// delete cmpgn
const deleteLsgApi = lsgId => {
  return del(`/lsg/admin/${lsgId}`)
}
// create District

function* onCreateDistrict({ payload }) {
  try {
    const response = yield call(createDistrictApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else if (response) {
      yield put(createDistrictSuccess(response))
      if (payload?.onCloseClick) {
        payload?.onCloseClick()
      }
      doneNotification("District Created Successfully!")
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createDistrictFail(error))
  }
}
// all District
function* fetchAllDistricts({ payload }) {
  try {
    const response = yield call(getAllDistrictsAPi, payload)
    yield put(getAllDistrictsSuccess(response))
  } catch (error) {
    yield put(getAllDistrictsFail(error))
  }
}
// update District

function* onUpdateDistrict({ payload }) {
  try {
    const response = yield call(updateDistrictApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "District Updated Successfully!",
        title: "",
      })
      yield put(updateDistrictSuccess(response))
      if (payload?.onCloseClick) {
        payload.onCloseClick()
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateDistrictFail(error.response.data))
  }
}
// delete District
function* onDeleteDistrict({ districtId, history }) {
  try {
    const response = yield call(deleteDistrictApi, districtId)
    yield put(deleteDistrictSuccess(response))
    Notification({
      type: "error",
      message: "District Deleted Successfully!",
      title: "",
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteDistrictFail(error))
  }
}

// create State

function* onCreateState({ payload }) {
  try {
    const response = yield call(createStateApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else if (response) {
      yield put(createStateSuccess(response))
      if (payload?.onCloseClick) {
        payload?.onCloseClick()
      }
      doneNotification("State Created Successfully!")
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createStateFail(error))
  }
}
// all State
function* fetchAllStates({ payload }) {
  try {
    const response = yield call(getAllStatesAPi, payload)
    yield put(getAllStatesSuccess(response))
  } catch (error) {
    yield put(getAllStatesFail(error))
  }
}
// update State

function* onUpdateState({ payload }) {
  try {
    const response = yield call(updateStateApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "State Updated Successfully!",
        title: "",
      })
      yield put(updateStateSuccess(response))
      if (payload?.onCloseClick) {
        payload.onCloseClick()
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateStateFail(error.response.data))
  }
}
// delete State
function* onDeleteState({ stateId, history }) {
  try {
    const response = yield call(deleteStateApi, stateId)
    yield put(deleteStateSuccess(response))
    Notification({
      type: "error",
      message: "State Deleted Successfully!",
      title: "",
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteStateFail(error))
  }
}

// create Taluk

function* onCreateTaluk({ payload }) {
  try {
    const response = yield call(createTalukApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else if (response) {
      yield put(createTalukSuccess(response))
      if (payload?.onCloseClick) {
        payload?.onCloseClick()
      }
      doneNotification("Taluk Created Successfully!")
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createTalukFail(error))
  }
}
// all Taluk
function* fetchAllTaluks({ payload }) {
  try {
    const response = yield call(getAllTaluksAPi, payload)
    yield put(getAllTaluksSuccess(response))
  } catch (error) {
    yield put(getAllTaluksFail(error))
  }
}
// update Taluk

function* onUpdateTaluk({ payload }) {
  try {
    const response = yield call(updateTalukApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Taluk Updated Successfully!",
        title: "",
      })
      yield put(updateTalukSuccess(response))
      if (payload?.onCloseClick) {
        payload.onCloseClick()
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateTalukFail(error.response.data))
  }
}
// delete Taluk
function* onDeleteTaluk({ talukId, history }) {
  try {
    const response = yield call(deleteTalukApi, talukId)
    yield put(deleteTalukSuccess(response))
    Notification({
      type: "error",
      message: "Taluk Deleted Successfully!",
      title: "",
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteTalukFail(error))
  }
}

// create lsg

function* onCreateLsg({ payload }) {
  try {
    const response = yield call(createLsgApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else if (response) {
      yield put(createLsgSuccess(response))
      if (payload?.onCloseClick) {
        payload?.onCloseClick()
      }
      doneNotification("Lsg Created Successfully!")
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createLsgFail(error))
  }
}
// all Lsg
function* fetchAllLsgs({ payload }) {
  try {
    const response = yield call(getAllLsgsAPi, payload)
    yield put(getAllLsgsSuccess(response))
  } catch (error) {
    yield put(getAllLsgsFail(error))
  }
}
// update

function* onUpdateLsg({ payload }) {
  try {
    const response = yield call(updateLsgApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Lsg Updated Successfully!",
        title: "",
      })
      yield put(updateLsgSuccess(response))
      if (payload?.onCloseClick) {
        payload.onCloseClick()
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateLsgFail(error.response.data))
  }
}
// delete Lsg
function* onDeleteLsg({ lsgId, history }) {
  try {
    const response = yield call(deleteLsgApi, lsgId)
    yield put(deleteLsgSuccess(response))
    Notification({
      type: "error",
      message: "Lsg Deleted Successfully!",
      title: "",
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteLsgFail(error))
  }
}
function* locationSaga() {
  yield takeEvery(CREATE_DISTRICT, onCreateDistrict)
  yield takeEvery(GET_ALL_DISTRICTS, fetchAllDistricts)
  yield takeEvery(UPDATE_DISTRICT, onUpdateDistrict)
  yield takeEvery(DELETE_DISTRICT, onDeleteDistrict)

  yield takeEvery(CREATE_STATE, onCreateState)
  yield takeEvery(GET_ALL_STATES, fetchAllStates)
  yield takeEvery(UPDATE_STATE, onUpdateState)
  yield takeEvery(DELETE_STATE, onDeleteState)

  yield takeEvery(CREATE_TALUK, onCreateTaluk)
  yield takeEvery(GET_ALL_TALUKS, fetchAllTaluks)
  yield takeEvery(UPDATE_TALUK, onUpdateTaluk)
  yield takeEvery(DELETE_TALUK, onDeleteTaluk)

  yield takeEvery(CREATE_LSG, onCreateLsg)
  yield takeEvery(GET_ALL_LSGS, fetchAllLsgs)
  yield takeEvery(UPDATE_LSG, onUpdateLsg)
  yield takeEvery(DELETE_LSG, onDeleteLsg)
}

export default locationSaga

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
