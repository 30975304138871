import MetaTag from "components/Common/Meta-tag"
import React from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Container } from "reactstrap"
import DataTables from "./DataTables"

function PromoterOrder() {
  return (
    <div>
      <MetaTag title={"All Order"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="All Order" />
        <Container fluid>
          <div className="container-fluid">
            <DataTables />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default PromoterOrder
