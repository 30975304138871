export const FORGET_PASSWORD = "FORGET_PASSWORD"
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS"
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR"

export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR"

export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR"
