//PROMOTERS

import { GET_DASHBOARD_DATA } from "store/dashboard/actionTypes"
import { error } from "toastr"
import {
  CHECK_PROMOTERID,
  CHECK_PROMOTERID_FAIL,
  CHECK_PROMOTERID_SUCCESS,
  CLEAR_STATE,
  CREATE_PROMOTER,
  CREATE_PROMOTER_FAIL,
  CREATE_PROMOTER_SUCCESS,
  DELETE_PROMOTER,
  DELETE_PROMOTER_FAIL,
  DELETE_PROMOTER_SUCCESS,
  GET_EARNING,
  GET_EARNING_FAIL,
  GET_EARNING_SUCCESS,
  GET_EARINIG_DETAILS,
  GET_EARINIG_DETAILS_FAIL,
  GET_EARINIG_DETAILS_SUCCESS,
  GET_PAGE_VIEW,
  GET_PAGE_VIEW_DETAILS,
  GET_PAGE_VIEW_DETAILS_FAIL,
  GET_PAGE_VIEW_DETAILS_SUCCESS,
  GET_PAGE_VIEW_FAIL,
  GET_PAGE_VIEW_SUCCESS,
  GET_PROMOTERS,
  GET_PROMOTERS_FAIL,
  GET_PROMOTERS_SUCCESS,
  GET_PROMOTER_DETAILS,
  GET_PROMOTER_DETAILS_FAIL,
  GET_PROMOTER_DETAILS_SUCCESS,
  UPDATE_PROMOTER,
  UPDATE_PROMOTER_FAIL,
  UPDATE_PROMOTER_SUCCESS,
  GET_ALL_FORMATTED_EARNINGS,
  GET_ALL_FORMATTED_EARNINGS_SUCCESS,
  GET_ALL_FORMATTED_EARNINGS_FAIL,
  GET_,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS,
  PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_SUCCESS,
  PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_FAIL,
  GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS,
  GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_SUCCESS,
  GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_FAIL,
  GET_PROMOTER_PRODUCT,
  GET_PROMOTER_PRODUCT_SUCCESS,
  GET_PROMOTER_PRODUCT_FAIL,
  GET_PROMOTER_PRODUCT_DETAILS,
  GET_PROMOTER_PRODUCT_DETAILS_SUCCESS,
  GET_PROMOTER_PRODUCT_DETAILS_FAIL,
  GET_ALL_PROMOTER_ORDER,
  GET_ALL_PROMOTER_ORDER_SUCCESS,
  GET_ALL_PROMOTER_ORDER_FAIL,
  GET_PROMOTER_ORDER_DETAILS,
  GET_PROMOTER_ORDER_DETAILS_SUCCESS,
  GET_PROMOTER_ORDER_DETAILS_FAIL,
  CREATE_PROMOTER_PUBLIC_FORM,
  CREATE_PROMOTER_PUBLIC_FORM_SUCCESS,
  CREATE_PROMOTER_PUBLIC_FORM_FAIL,
  GET_COUNTRY_CODE,
  GET_COUNTRY_CODE_SUCCESS,
  GET_COUNTRY_CODE_FAIL,
} from "./actionTypes"

//all users
// export const getPromoters = (page, searchText) => ({
//   type: GET_PROMOTERS,
//   payload: { page, searchText },
// })

// export const getPromotersSuccess = promotersList => ({
//   type: GET_PROMOTERS_SUCCESS,
//   payload: promotersList,
// })

// export const getPromotersFail = error => ({
//   type: GET_PROMOTERS_FAIL,
//   payload: error,
// })

//user details
// export const getPromoterDetails = promoterId => ({
//   type: GET_PROMOTER_DETAILS,
//   payload: promoterId,
// })

// export const getPromoterDetailsSuccess = promoterDetails => ({
//   type: GET_PROMOTER_DETAILS_SUCCESS,
//   payload: promoterDetails,
// })

// export const getPromoterDetailsFail = error => ({
//   type: GET_PROMOTER_DETAILS_FAIL,
//   payload: error,
// })

//CREATE PROMOTER
// export const createPromoters = (promoterData, history) => (
//   console.log(promoterData),
//   {
//     type: CREATE_PROMOTER,
//     payload: { promoterData, history },
//   }
// )

// export const createPromotersSuccess = promoter => ({
//   type: CREATE_PROMOTER_SUCCESS,
//   payload: promoter,
// })

// export const createPromotersFail = error => ({
//   type: CREATE_PROMOTER_FAIL,
//   payload: error,
// })

//update user
// export const updatePromoter = (promoterData, promoterId, history) => (
//   console.log(promoterId),
//   {
//     type: UPDATE_PROMOTER,
//     payload: { promoterData, promoterId, history },
//   }
// )

// export const updatePromoterSuccess = promoter => ({
//   type: UPDATE_PROMOTER_SUCCESS,
//   payload: promoter,
// })

// export const updatePromoterFail = error => ({
//   type: UPDATE_PROMOTER_FAIL,
//   payload: error,
// })

//delete user
// export const deletePromoter = (promoterId, history) => (
//   console.log(promoterId),
//   {
//     type: DELETE_PROMOTER,
//     payload: { promoterId, history },
//   }
// )

// export const deletePromoterSuccess = (user, id) => ({
//   type: DELETE_PROMOTER_SUCCESS,
//   payload: { user, id },
// })

// export const deletePromoterFail = error => ({
//   type: DELETE_PROMOTER_FAIL,
//   payload: error,
// })

// export const checkPromoterId = (ref_id, checkPromoter) => ({
//   type: CHECK_PROMOTERID,
//   payload: { ref_id, checkPromoter },
// })

// export const checkPromoterIdSuccess = response => ({
//   type: CHECK_PROMOTERID_SUCCESS,
//   payload: { response },
// })

// export const checkPromoterIdFail = error => ({
//   type: CHECK_PROMOTERID_FAIL,
//   payload: error,
// })

// page view Details

// export const getPageViewDetails = (pageViewId, page) => ({
//   type: GET_PAGE_VIEW_DETAILS,
//   payload: { pageViewId, page },
// })

// export const getPageViewDetailsSuccess = pageViewDetails => ({
//   type: GET_PAGE_VIEW_DETAILS_SUCCESS,
//   payload: pageViewDetails,
// })

// export const getPageViewDetailsFail = error => ({
//   type: GET_PAGE_VIEW_DETAILS_FAIL,
//   payload: error,
// })

// Earning

// export const getEarningsDetails = (earningsId, page) => ({
//   type: GET_EARINIG_DETAILS,
//   payload: { earningsId, page },
// })
// export const getEarningsDetailSuccess = earningDetails => ({
//   type: GET_EARINIG_DETAILS_SUCCESS,
//   payload: earningDetails,
// })
// export const getEarningsDetailsFail = error => ({
//   type: GET_EARINIG_DETAILS_FAIL,
//   payload: error,
// })
// // All Page view

// export const getPageView = (page, limit, searchText, sortByDate) => ({
//   type: GET_PAGE_VIEW,
//   payload: { page, limit, searchText, sortByDate },
// })

// export const getPageViewSuccess = pageView => ({
//   type: GET_PAGE_VIEW_SUCCESS,
//   payload: pageView,
// })

// export const getPageViewFail = error => ({
//   type: GET_PAGE_VIEW_FAIL,
//   payload: error,
// })

// // All Earnings
// export const getEarnings = (page, limit, searchText, sortByDate) => ({
//   type: GET_EARNING,
//   payload: { page, limit, searchText, sortByDate },
// })
// export const getEarningsSuccess = earnings => ({
//   type: GET_EARNING_SUCCESS,
//   payload: earnings,
// })
// export const getEarningsFail = error => ({
//   type: GET_EARNING_FAIL,
//   payload: error,
// })

// // All Formatted Earnings

// export const getAllFormattedEarnings = (
//   page,
//   limit,
//   searchText,
//   sortByDate
// ) => ({
//   type: GET_ALL_FORMATTED_EARNINGS,
//   payload: { page, limit, searchText, sortByDate },
// })

// export const getAllFormattedEarningsSuccess = formattedEarnings => ({
//   type: GET_ALL_FORMATTED_EARNINGS_SUCCESS,
//   payload: formattedEarnings,
// })

// export const getAllFormattedEarningsFail = error => ({
//   type: GET_ALL_FORMATTED_EARNINGS_FAIL,
//   payload: error,
// })

// PROMOTER DASHBOARD
// page views

export const getPromoterDashbordPageview = page => ({
  type: PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS,
  payload: page,
})

export const getPromoterDashbordPageviewSuccess = dashboardPageView => ({
  type: PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_SUCCESS,
  payload: dashboardPageView,
})

export const getPromoterDashboardPageviewFail = error => ({
  type: PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_FAIL,
  payload: error,
})

// earnings

export const getPromoterDashboardEanings = page => ({
  type: GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS,
  payload: page,
})

export const getPromoterDashboardEarningsSuccess = dashboardEarnings => ({
  type: GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_SUCCESS,
  payload: dashboardEarnings,
})

export const getPromoterDashboardEaningsFail = error => ({
  type: GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_FAIL,
  payload: error,
})

//  promoter product

export const getPromoterProduct = (search, page) => ({
  // export const getPromoterProduct = () => ({
  type: GET_PROMOTER_PRODUCT,
  payload: { search, page },
  // payload: "",
})

export const getPromoterProductSuccess = promoterProduct => ({
  type: GET_PROMOTER_PRODUCT_SUCCESS,
  payload: promoterProduct,
})

export const getPromoterProductFail = error => ({
  type: GET_PROMOTER_PRODUCT_FAIL,
  payload: error,
})

// promoter product details

export const getPromoterProductDetails = productId => ({
  type: GET_PROMOTER_PRODUCT_DETAILS,
  payload: productId,
})

export const getPromoterProductDetailsSuccess = promoterProductDetails => ({
  type: GET_PROMOTER_PRODUCT_DETAILS_SUCCESS,
  payload: promoterProductDetails,
})

export const getPromoterProductDetailsFail = error => ({
  type: GET_PROMOTER_PRODUCT_DETAILS_FAIL,
  payload: error,
})

// promoter public form

export const createPromoterPublicForm = (publicPromoter, successFunc) => ({
  type: CREATE_PROMOTER_PUBLIC_FORM,
  payload: { publicPromoter, successFunc },
})

export const createPromoterPublicFormSuccess = promoter => ({
  type: CREATE_PROMOTER_PUBLIC_FORM_SUCCESS,
  payload: promoter,
})

export const createPromoterPublicFormFail = error => ({
  type: CREATE_PROMOTER_PUBLIC_FORM_FAIL,
  payload: error,
})

//
export const getCountryCode = (searchText, page, limit) => ({
  type: GET_COUNTRY_CODE,
  payload: { searchText, page, limit },
})

export const getCountryCodeSuccess = CountryCode => ({
  type: GET_COUNTRY_CODE_SUCCESS,
  payload: CountryCode,
})

export const getCountryCodeFail = error => ({
  type: GET_COUNTRY_CODE_FAIL,
  payload: error,
})
