import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  FormGroup,
  Badge,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//actions
import { deleteBlog, getBlogs, updateBlog } from "store/actions"

import "../../assets/scss/datatables.scss"

import MyPagination from "components/Common/MyPagination"
import DeleteModal from "components/Common/DeleteModal"
import BlogModal from "./Crud/Modal"
import { defaultImg } from "assets/images"
import { Markup } from "interweave"
import { AvField, AvForm } from "availity-reactstrap-validation"
import useTextTruncate from "hooks/useTextTruncate"

const BlogsList = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")
  const [blogModal, setBlogModal] = useState({ status: false, data: {} })

  const { loading, blogs, prodLoading, products } = useSelector(state => ({
    loading: state.Blogs.loading,
    blogs: state.Blogs.blogs,
    prodLoading: state.Products.loading,
    products: state.Products.products,
  }))

  console.log(blogs)

  const totalPages = Math.ceil(blogs?.total / 10)
  const pages = range(1, totalPages + 1)

  const [sort, setSort] = useState("all")

  useEffect(() => {
    dispatch(getBlogs(page, 10, searchText, sort))
  }, [dispatch, page, searchText, sort])

  const [isOpen, setIsOpen] = useState({ status: false, id: "" })

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteBlog(isOpen?.id))
  }

  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "content",
      text: "Content",
      sort: true,
    },
    {
      dataField: "imageData",
      filterText: "image",
      text: "Image",
      sort: true,
    },

    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    // {
    //   dataField: "activeData",
    //   text: "Status",
    // },
    // {
    //   dataField: "isPublished",
    //   text: "Published",
    // },
    // {
    //   dataField: "isHighlighted",
    //   text: "Type",
    // },

    {
      dataField: "action",
      text: "Action",
    },
  ]

  const handleSwitchChange = (item, e) => {
    const blog = {
      ...item,
      [e.target.name]: e.target.checked,
    }
    const pagination = {
      page,
      limit: 10,
      searchText,
      sort,
    }
    dispatch(updateBlog(blog, item?._id, "", true, pagination))
  }

  // const tableData = map(blogs?.blogs, (item, index) => (

  //   {
  //   ...item,
  //   imageData: (
  //     <>
  //       <img
  //         src={
  //           item?.images && item?.images[0] ? item?.images[0]?.url : defaultImg
  //         }
  //         width="50px"
  //       />
  //     </>
  //   ),
  //   description: (
  //     <div style={{ whiteSpace: "break-spaces" }}>{truncatedDescription}</div>
  //   ),
  //   title: (
  //     <div className="d-flex algin-items-center text-start   justify-content-start ">
  //       <p className="mb-0 mt-1">{item?.title}</p>
  //       {sort === "all" && (
  //         <>
  //           {item?.isPublished && (
  //             <Badge
  //               className={"font-size-11 mx-2 p-2 badge-soft-success "}
  //               style={{
  //                 width: "fit-content",
  //               }}
  //               pill
  //             >
  //               Published
  //             </Badge>
  //           )}
  //           {item?.isHighlighted && (
  //             <Badge
  //               className={"font-size-11 me-2 p-2 badge-soft-info  "}
  //               style={{
  //                 width: "fit-content",
  //               }}
  //               pill
  //             >
  //               Highlighted
  //             </Badge>
  //           )}
  //         </>
  //       )}
  //     </div>
  //   ),
  //   content: (
  //     <div
  //       className="text-break-spaces position-relative"
  //       style={{ maxWidth: 350 }}
  //     >
  //       <Markup content={item?.content?.slice(0, 140)} className="markup" />
  //       <span
  //         style={{
  //           position: "absolute",
  //           bottom: 0,
  //           right: 100,
  //         }}
  //       >
  //         {" "}
  //         {item?.content?.length > 25 ? "..." : ""}
  //       </span>
  //     </div>
  //   ),
  //   // activeData: (
  //   //   <>
  //   //     <div className="form-check form-switch form-switch-md mb-3">
  //   //       <input
  //   //         type="checkbox"
  //   //         className="form-check-input"
  //   //         id="customSwitchsizemd"
  //   //         onChange={e => {
  //   //           handleSwitchChange(item, e)
  //   //         }}
  //   //         name="isActive"
  //   //         checked={item?.isActive}
  //   //         style={{ cursor: "pointer" }}
  //   //       />
  //   //       <label className="form-check-label" htmlFor="customSwitchsizemd">
  //   //         {item?.isActive ? `Active` : `Inactive`}
  //   //       </label>
  //   //     </div>
  //   //   </>
  //   // ),
  //   // isPublished: (
  //   //   <>
  //   //     <div className="form-check form-switch form-switch-md mb-3">
  //   //       <input
  //   //         type="checkbox"
  //   //         className="form-check-input"
  //   //         id="customSwitchsizemd"
  //   //         onChange={e => {
  //   //           handleSwitchChange(item, e)
  //   //         }}
  //   //         name="isPublished"
  //   //         checked={item?.isPublished}
  //   //         style={{ cursor: "pointer" }}
  //   //       />
  //   //       <label className="form-check-label" htmlFor="customSwitchsizemd">
  //   //         {item?.isPublished ? `Yes` : `No`}
  //   //       </label>
  //   //     </div>
  //   //   </>
  //   // ),
  //   // isHighlighted: (
  //   //   <>
  //   //     <div className="form-check form-switch form-switch-md mb-3">
  //   //       <input
  //   //         type="checkbox"
  //   //         className="form-check-input"
  //   //         id="customSwitchsizemd"
  //   //         onChange={e => {
  //   //           handleSwitchChange(item, e)
  //   //         }}
  //   //         name="isHighlighted"
  //   //         style={{ cursor: "pointer" }}
  //   //         checked={item?.isHighlighted}
  //   //       />
  //   //       <label className="form-check-label" htmlFor="customSwitchsizemd">
  //   //         {item?.isHighlighted ? `Highlighted` : `Normal`}
  //   //       </label>
  //   //     </div>
  //   //   </>
  //   // ),
  //   action: (
  //     <div>
  //       <Link
  //         to={`#`}
  //         className="btn-md"
  //         onClick={() => setBlogModal({ status: true, data: item })}
  //       >
  //         <i className="bx bx-edit me-2 " style={{ fontSize: "1.3rem" }} />
  //       </Link>
  //       <Button
  //         id={`Delete${index}`}
  //         type="button"
  //         color="white"
  //         className="ms-1 btn "
  //         onClick={() => {
  //           handleDelete(item._id)
  //         }}
  //       >
  //         <i
  //           className="bx bx-trash me-2"
  //           style={{ color: "red", marginBottom: "10px", fontSize: "1.3rem" }}
  //         />
  //       </Button>
  //     </div>
  //   ),
  // }))

  const tableData = map(blogs?.blogs, (item, index) => {
    // const truncatedText = useTextTruncate(item?.description, maxLength)

    return {
      ...item,
      imageData: (
        <>
          <img
            src={
              item?.images && item?.images[0]
                ? item?.images[0]?.url
                : defaultImg
            }
            width="50px"
          />
        </>
      ),
      title: (
        <div className="d-flex algin-items-center text-start   justify-content-start ">
          <p className="mb-0 mt-1">{item?.title}</p>
          {sort === "all" && (
            <>
              {item?.isPublished && (
                <Badge
                  className={"font-size-11 mx-2 p-2 badge-soft-success "}
                  style={{
                    width: "fit-content",
                  }}
                  pill
                >
                  Published
                </Badge>
              )}
              {item?.isHighlighted && (
                <Badge
                  className={"font-size-11 me-2 p-2 badge-soft-info  "}
                  style={{
                    width: "fit-content",
                  }}
                  pill
                >
                  Highlighted
                </Badge>
              )}
            </>
          )}
        </div>
      ),
      description: (
        <div style={{ whiteSpace: "break-spaces" }}>
          {item?.description?.length > 25 ? (
            <>{item.description.substring(0, 25)}...</>
          ) : (
            item.description
          )}
        </div>
      ),
      content: (
        <div
          className="text-break-spaces position-relative"
          style={{ maxWidth: 350 }}
        >
          <Markup content={item?.content?.slice(0, 140)} className="markup" />
          <span
            style={{
              position: "absolute",
              bottom: 0,
              right: 100,
            }}
          >
            {" "}
            {item?.content?.length > 15 ? "" : ""}
          </span>
        </div>
      ),
      action: (
        <div>
          <Link
            to={`#`}
            className="btn-md"
            onClick={() => setBlogModal({ status: true, data: item })}
          >
            <i className="bx bx-edit me-2 " style={{ fontSize: "1.3rem" }} />
          </Link>
          <Button
            id={`Delete${index}`}
            type="button"
            color="white"
            className="ms-1 btn "
            onClick={() => {
              handleDelete(item._id)
            }}
          >
            <i
              className="bx bx-trash me-2"
              style={{ color: "red", marginBottom: "10px", fontSize: "1.3rem" }}
            />
          </Button>
        </div>
      ),
    }
  })

  const debounceBlogSearch = debounce(value => setSearchText(value), 600)

  const { SearchBar } = Search

  const listChange = sortValue => {
    setSort(sortValue)
  }

  const headerTap = [
    {
      value: "all",
      title: "All",
    },

    {
      value: "highlighted",
      title: "Highlights",
    },
    {
      value: "published",
      title: "Published",
    },
    {
      value: "draft",
      title: "Drafts",
    },
    {
      value: "active",
      title: "Active",
    },
    {
      value: "inactive",
      title: "inActive",
    },
  ]

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ data: {}, status: false })}
          onDeleteClick={handleDeleteEvent}
        />
        <BlogModal
          onCloseClick={() => setBlogModal({ status: false, data: {} })}
          show={blogModal?.status}
          updateData={blogModal?.data}
        />
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={"id"}
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col lg={5} className="mb-3">
                        <div
                          className="btn-group mt-xl-0"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          {headerTap?.map((tap, key) => (
                            <Fragment key={key}>
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnRadio"
                                id={`btnRadio${tap?.value}`}
                                autoComplete="off"
                                checked={tap.value === sort ? true : false}
                                onChange={() => listChange(tap?.value)}
                              />
                              <label
                                className="btn btn-success"
                                htmlFor={`btnRadio${tap?.value}`}
                              >
                                {tap?.title}
                              </label>
                            </Fragment>
                          ))}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="search-box w-100 ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                onChange={e =>
                                  debounceBlogSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            {/* <SearchBar {...toolkitProps.searchProps} /> */}
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={() => setBlogModal({ status: true })}
                          >
                            <i className="mdi mdi-plus me-1" />
                            Create Blog
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {blogs?.total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Blogs !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default BlogsList
