import MetaTag from "components/Common/Meta-tag"
import { Notification } from "components/Common/Notification"
import { debounce, map, range } from "lodash"
import MyPagination from "components/Common/MyPagination"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Container,
  Input,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap"
import {
  getProductsByCategory,
  getPromoterProduct,
  getPromoterProfileDetails,
} from "store/actions"
import noImage from "assets/images/Defualt/noimage3.png"
import CategoryList from "./CategoryList"
import useCloudinary from "hooks/useCloudinaryImage"

function Products() {
  const dispatch = useDispatch()
  const [page, setPage] = useState("1")
  const [search, setSearch] = useState("")

  const { loading, products, promoterProfile, sortLoading } = useSelector(
    state => ({
      loading: state.Promoter.productLoading,
      products: state?.Promoter?.promoterProduct,
      promoterProfile: state?.Dashboard?.promoterProfile,
      sortLoading: state.Category?.loading,
    })
  )

  console.log(products?.products)
  const total = products?.total

  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const copyHanlde = value => {
    const removeSpace = value?.replace(/ /g, "-")
    const text = `https://adrakshoppe.com/products/${removeSpace}?ref_id=${promoterProfile?.ref_id}`
    navigator?.clipboard?.writeText(text)
    Notification({
      type: "success",
      message: "url copied",
      title: "",
    })
  }

  useEffect(() => {
    dispatch(getPromoterProduct(search, page))
    dispatch(getPromoterProfileDetails())
  }, [dispatch, search, page])

  const debounceOrderSearch = debounce(value => setSearch(value), 600)

  const [sortValue, setSortValue] = useState("")
  useEffect(() => {
    if (sortValue) {
      dispatch(getProductsByCategory(sortValue))
    }
  }, [dispatch, sortValue])

  return (
    <div>
      <MetaTag title="Products" />

      <div className="page-content ">
        <Container fluid>
          <div className="container-fluid ">
            <div className="w-25 m-2 ">
              <p className="text-muted font-size-18">All Products</p>
              <div style={{ position: "relative" }}>
                <i
                  className="bx bx-search-alt-2 font-size-22 text-muted"
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    bottom: 0,
                  }}
                />
                <Input
                  placeholder=" Search ... "
                  type="search"
                  onChange={e => debounceOrderSearch(e.target.value)}
                  className="rounded-pill mt-1"
                ></Input>
              </div>
            </div>
            <hr />
            <div className="categories-dropdown-wrap categories-dropdown-active-large font-heading p-2">
              <div className="category">
                <CategoryList
                  sortValue={sortValue}
                  setSortValue={setSortValue}
                  className="list_cat"
                />
              </div>
            </div>

            <div>
              <div className="d-flex flex-wrap p-2">
                {map(products?.products, (item, key) => (
                  <Card
                    key={key}
                    className="product-card mx-3 hvr-grow-shadow col-lg-3 col-md-3 col-sm-6 col-12"
                    style={{
                      borderRadius: "6%",
                      minHeight: "400px",
                    }}
                  >
                    <div className="position-relative">
                      <div className="avathar-md product-copy">
                        <span className="avatar-title rounded-circle bg-white">
                          <i
                            onClick={() => copyHanlde(item?.primaryLang?.name)}
                            className="bx bx-copy icon text-muted "
                          />
                        </span>
                      </div>
                    </div>
                    <CardBody>
                      <Link to={`/promoter/product/${item?._id}`}>
                        <div className="h-75 d-flex align-item-center justify-content-center pb-2">
                          <img
                            src={
                              item?.images[0]?.url
                                ? useCloudinary(
                                    item?.images[0]?.url,
                                    "w_400,q_80"
                                  )
                                : noImage
                            }
                            className="img-fluid product-img center  bg-transparent"
                            style={{
                              objectFit: "contain",
                            }}
                          />
                        </div>
                        <div>
                          <p className="font-size-14">
                            {item?.primaryLang.name?.slice(0, 35)} &nbsp;
                            {item?.primaryLang.name.length > 35 && "..."}
                          </p>
                          <div className="d-flex">
                            <del className="text-dark font-size-15">
                              {item?.price}
                            </del>
                            &nbsp;&nbsp;
                            <p
                              className="text-success font-size-18 "
                              style={{ fontFamily: "sans-serif" }}
                            >
                              ₹{item?.offerPrice}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                ))}
              </div>

              {loading || sortLoading ? (
                <>
                  <Spinner color="secondary" className="d-block m-auto" />
                </>
              ) : (
                <>
                  {products?.total === 0 && (
                    <p className="my-2 mx-3 ">No Products!</p>
                  )}
                </>
              )}
              <MyPagination totalPages={pages} page={page} setPage={setPage} />
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Products
