import MetaTag from "components/Common/Meta-tag"
import { LanguageSwitch } from "hooks/LanguageSwitch"
import { map, range } from "lodash"
import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { useDispatch, useSelector } from "react-redux"
import { Badge, Card, Col, Container, Label, Row, Spinner } from "reactstrap"
import { getPromoterDashboardEanings } from "store/promoter/actions"

function promoterEarnings() {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)

  const { loading, earnings } = useSelector(state => ({
    earnings: state?.Promoter?.dashboardEarnings,
    loading: state?.Promoter?.dashboardLoading,
  }))

  useEffect(() => {
    dispatch(getPromoterDashboardEanings(page))
  }, [dispatch, page])
  const total = earnings?.total

  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }
  const PreColumns = [
    {
      dataField: "orderId",
      text: "Order ID",
    },
    {
      dataField: "orderAmount",
      text: " Order Amount",
    },
    {
      dataField: "totalEarningsAmount",
      text: " Rewards Amount",
    },
    {
      dataField: "orderStatus",
      text: "Status",
    },
  ]

  const handleOrderStatus = status => {
    switch (status) {
      case "Processing":
        return "info"
      case "Shipped":
        return "info"
      case "Delivered":
        return "success"
      case "Cancelled":
        return "warning"
      case "Returned":
        return "danger"
      case "Fulfilled":
        return "success"
      case "Partially Fulfilled":
        return "success"
      default:
        return "info"
    }
  }
  const columns = LanguageSwitch()?.isMult
    ? PreColumns
    : PreColumns?.filter(i => i.text !== "Secondary Name")

  const dashboardEarning = map(earnings?.earnings, (item, index) => ({
    ...item,
    orderId: <h6>{item?.order?.orderId}</h6>,
    orderAmount: <span>{item?.orderAmount}</span>,
    totalEarningsAmount: (
      <h6 className="text-dark">{earnings?.totalEarningsAmount}</h6>
    ),
    orderStatus: (
      <Badge
        className={
          "px-2 py-2 badge-soft-" +
          `${handleOrderStatus(item?.order?.orderStatus)}`
        }
      >
        {item?.order?.orderStatus}
      </Badge>
    ),
  }))

  return (
    <div>
      <MetaTag title={"Rewards"} />
      <div className="page-content">
        <Container fluid>
          <div className="container-fluid">
            <Card className="shadow p-3 mb-5 bg-white rounded">
              <div>
                {/* <Label className="text-muted">Earning Details</Label> */}
              </div>
              <Label>
                {earnings?.total === 0 ? (
                  <p className="text-warning "> no Rewards to see</p>
                ) : (
                  <>
                    <Col className="">
                      <ToolkitProvider
                        keyField="_id"
                        columns={columns}
                        data={dashboardEarning}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            {loading ? (
                              <Spinner
                                color="secondary"
                                className="d-block m-auto"
                              />
                            ) : (
                              <>
                                <Row>
                                  <Col>
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        // keyField={"id"}
                                        key={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        // defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap floting-action"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        // onTableChange={handleTableChange}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                {/* <MyPagination
                                  pages={pages}
                                  clcickedPage={page}
                                  onNunClick={item => setPage(item)}
                                  onNextClick={() => setPage(page + 1)}
                                  onPrevClick={() => setPage(page - 1)}
                                  apiPage={pageSend}
                                /> */}
                              </>
                            )}
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </Col>
                  </>
                )}
              </Label>
            </Card>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default promoterEarnings
