/* Create district*/
export const CREATE_DISTRICT = "CREATE_DISTRICT"
export const CREATE_DISTRICT_SUCCESS = "CREATE_DISTRICT_SUCCESS"
export const CREATE_DISTRICT_FAIL = "CREATE_DISTRICT_FAIL"

/* ALL district */
export const GET_ALL_DISTRICTS = "GET_ALL_DISTRICTS"
export const GET_ALL_DISTRICTS_SUCCESS = "GET_ALL_DISTRICTS_SUCCESS"
export const GET_ALL_DISTRICTS_FAIL = "GET_ALL_DISTRICTS_FAIL"

/* Update district*/
export const UPDATE_DISTRICT = "UPDATE_DISTRICT"
export const UPDATE_DISTRICT_SUCCESS = "UPDATE_DISTRICT_SUCCESS"
export const UPDATE_DISTRICT_FAIL = "UPDATE_DISTRICT_FAIL"

/* delete district */
export const DELETE_DISTRICT = "DELETE_DISTRICT"
export const DELETE_DISTRICT_SUCCESS = "DELETE_DISTRICT_SUCCESS"
export const DELETE_DISTRICT_FAIL = "DELETE_DISTRICT_FAIL"

/* Create state*/
export const CREATE_STATE = "CREATE_STATE"
export const CREATE_STATE_SUCCESS = "CREATE_STATE_SUCCESS"
export const CREATE_STATE_FAIL = "CREATE_STATE_FAIL"

/* ALL STATE */
export const GET_ALL_STATES = "GET_ALL_STATES"
export const GET_ALL_STATES_SUCCESS = "GET_ALL_STATES_SUCCESS"
export const GET_ALL_STATES_FAIL = "GET_ALL_STATES_FAIL"

/* Update STATE*/
export const UPDATE_STATE = "UPDATE_STATE"
export const UPDATE_STATE_SUCCESS = "UPDATE_STATE_SUCCESS"
export const UPDATE_STATE_FAIL = "UPDATE_STATE_FAIL"

/* delete STATE */
export const DELETE_STATE = "DELETE_STATE"
export const DELETE_STATE_SUCCESS = "DELETE_STATE_SUCCESS"
export const DELETE_STATE_FAIL = "DELETE_STATE_FAIL"

/* Create Taluk*/
export const CREATE_TALUK = "CREATE_TALUK"
export const CREATE_TALUK_SUCCESS = "CREATE_TALUK_SUCCESS"
export const CREATE_TALUK_FAIL = "CREATE_TALUK_FAIL"

/* ALL TALUK */
export const GET_ALL_TALUKS = "GET_ALL_TALUKS"
export const GET_ALL_TALUKS_SUCCESS = "GET_ALL_TALUKS_SUCCESS"
export const GET_ALL_TALUKS_FAIL = "GET_ALL_TALUKS_FAIL"

/* Update TALUK*/
export const UPDATE_TALUK = "UPDATE_TALUK"
export const UPDATE_TALUK_SUCCESS = "UPDATE_TALUK_SUCCESS"
export const UPDATE_TALUK_FAIL = "UPDATE_TALUK_FAIL"

/* delete TALUK */
export const DELETE_TALUK = "DELETE_TALUK"
export const DELETE_TALUK_SUCCESS = "DELETE_TALUK_SUCCESS"
export const DELETE_TALUK_FAIL = "DELETE_TALUK_FAIL"

/* Create LSG*/
export const CREATE_LSG = "CREATE_LSG"
export const CREATE_LSG_SUCCESS = "CREATE_LSG_SUCCESS"
export const CREATE_LSG_FAIL = "CREATE_LSG_FAIL"

/* ALL LSG */
export const GET_ALL_LSGS = "GET_ALL_LSGS"
export const GET_ALL_LSGS_SUCCESS = "GET_ALL_LSGS_SUCCESS"
export const GET_ALL_LSGS_FAIL = "GET_ALL_LSGS_FAIL"

/* Update LSG*/
export const UPDATE_LSG = "UPDATE_LSG"
export const UPDATE_LSG_SUCCESS = "UPDATE_LSG_SUCCESS"
export const UPDATE_LSG_FAIL = "UPDATE_LSG_FAIL"

/* delete LSG */
export const DELETE_LSG = "DELETE_LSG"
export const DELETE_LSG_SUCCESS = "DELETE_LSG_SUCCESS"
export const DELETE_LSG_FAIL = "DELETE_LSG_FAIL"
