import React from "react"
import { Container } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DistrictDataTables from "./DistrictDataTables"
import MetaTag from "components/Common/Meta-tag"

function Districts() {
  return (
    <>
      <MetaTag title={"All Districts"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="All Districts" />
        <Container fluid>
          <div className="container-fluid">
            <DistrictDataTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Districts
