import React, { Fragment, useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Button, Spinner } from "reactstrap"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { map, range } from "lodash"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "../../components/Common/MyPagination"

//actions
import { LanguageSwitch } from "hooks/LanguageSwitch"
import {
  deletePages,
  getAllDeletedPages,
  getAllHomePages,
  updateHomePageStatus,
  getPages,
  hardDeletePage,
  getRestorePage,
  getDuplicatePage,
  updatePages,
} from "store/actions"

import "../../assets/scss/datatables.scss"
import DeleteModal from "components/Common/DeleteModal"
import { Notification } from "components/Common/Notification"
import { MdCopyAll } from "react-icons/md"

function ProductPagesList() {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { pagesList, loading, total } = useSelector(state => ({
    pagesList: state.Pages.pages?.pages,
    total: state.Pages.pages?.total,
    loading: state.Pages.loading,
  }))

  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")
  const [limit, setLimit] = useState("10")
  // const [pageTab, setPageTab] = useState("AllPages")
  const [sort, setSort] = useState("all")

  const [isOpen, setIsOpen] = useState({ stauts: false, id: "" })

  useEffect(() => {
    // if (pageTab === "AllPages") {
    dispatch(getPages(page, limit, searchText, sort))
    // } else if (pageTab === "HomePages") {
    //   dispatch(getAllHomePages(page, limit, searchText))
    // } else {
    //   dispatch(getAllDeletedPages(page, limit, searchText))
    // }
  }, [dispatch, page, searchText, limit, sort])

  // const handleData = () => {
  //   if (pageTab === "AllPages") {
  //     return pagesList?.pages
  //   } else if (pageTab === "HomePages") {
  //     return homePages
  //   } else {
  //     return deleted?.pages
  //   }
  // }

  // useEffect(() => {
  //   setPage(1)
  // }, [pageTab])

  // const pageNumber = () => {
  //   if (pageTab === "AllPages") {
  //     return pagesList?.total
  //   } else if (pageTab === "HomePages") {
  //     return homePages?.total
  //   } else {
  //     return deleted?.total
  //   }
  // }

  //pagination
  const totalPages = Math.ceil(total / limit)
  const pages = range(1, totalPages + 1)

  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "product",
      text: "Product Name",
      sort: true,
    },
    {
      dataField: "section",
      text: "Section Count",
      sort: true,
    },

    {
      dataField: "activePage",
      text: "Status",
      sort: true,
    },
    {
      dataField: "all",
      text: "Status",
      sort: true,
    },
    {
      dataField: "action",
      text: "Actions",
    },
  ]

  const handleColumns = () => {
    if (sort === "home" || sort === "all") {
      return columns
    } else {
      return columns?.filter(i => i.dataField !== "activePage")
    }
  }

  const copyHandler = value => {
    const removeSpace = value?.replace(/ /g, "-")
    const text = `https://fzone.in/pages/${removeSpace}`
    navigator?.clipboard?.writeText(text)
    Notification({
      type: "success",
      message: "text copied",
      title: "",
    })
  }

  const handleHardDelete = pageId => {
    dispatch(hardDeletePage(pageId))
  }

  const hanldeRestore = pageId => {
    dispatch(getRestorePage(pageId))
  }

  const handleDuplicate = pageId => {
    dispatch(getDuplicatePage(pageId))
  }

  const groupsData = map(pagesList, (item, index) => ({
    ...item,
    title: (
      <>
        <span className="me-3">{item?.title}</span>
        <MdCopyAll
          id={`copy${index}`}
          className="cursor-pointer font-size-18"
          onClick={() => copyHandler(item?.title)}
          title="copy url"
        />
      </>
    ),
    section: <span>{item?.sections?.length}</span>,
    product: (
      <>
        <p style={{ maxWidth: "250px", whiteSpace: "break-spaces" }}>
          {item?.product?.primaryLang?.name}
        </p>
      </>
    ),
    activePage: (
      <>
        {sort === "all" ? (
          <div className="form-check form-switch form-switch-md me-4">
            <label className="form-check-label" htmlFor="isHomeActiveSwitch">
              {item?.isActive ? `Active` : `Inactive`}
            </label>
            <input
              type="checkbox"
              className={
                "form-check-input " + `${item?.isActive && "bg-success"}`
              }
              id="isHomeActiveSwitch"
              style={{
                border: item?.isActive && "none",
              }}
              onChange={e => {
                dispatch(
                  updatePages(
                    {
                      ...item,
                      product: item?.product?._id,
                      isActive: e.target.checked,
                    },
                    item?._id
                  )
                )
              }}
              checked={item?.isActive}
            />
          </div>
        ) : (
          <div className="form-check form-switch form-switch-md me-4">
            <label className="form-check-label" htmlFor="isHomeActiveSwitch">
              {item?.isActive ? `Active` : `Inactive`}
            </label>
            <input
              type="checkbox"
              className={
                "form-check-input " + `${item?.isActive && "bg-success"}`
              }
              id="isHomeActiveSwitch"
              style={{
                border: item?.isActive && "none",
              }}
              onChange={e => {
                dispatch(updateHomePageStatus(item?._id))
              }}
              checked={item?.isActive}
            />
          </div>
        )}
      </>
    ),

    action: (
      <div>
        {sort === "deleted" ? (
          <>
            <Link to={`/pages/product/${item?._id}`}>
              <Button
                type="button"
                color="white"
                className="ms-1 btn"
                title="view"
              >
                <i
                  className="bx bx-show-alt me-2"
                  style={{ color: "green", fontSize: "22px" }}
                />
              </Button>
            </Link>

            <Button
              type="button"
              color="white"
              className="ms-1 btn"
              onClick={() => hanldeRestore(item?._id)}
              title="Restore"
            >
              <i
                className="bx bx-revision me-2"
                style={{ color: "green", fontSize: "20px" }}
              />
            </Button>
            <Button
              type="button"
              color="white"
              className="ms-1 btn"
              onClick={() => handleHardDelete(item?._id)}
              title="hard delete"
            >
              <i
                className="bx bx-error-alt me-2"
                style={{ color: "red", fontSize: "20px" }}
              />
            </Button>
          </>
        ) : (
          <>
            <Link to={`/pages/product/${item?._id}`}>
              <Button
                type="button"
                color="white"
                className="ms-1 btn"
                title="view"
              >
                <i
                  className="bx bx-show-alt me-2"
                  style={{ color: "green", fontSize: "22px" }}
                />
              </Button>
            </Link>
            <Button
              color="white"
              onClick={() => handleDuplicate(item?._id)}
              title="duplicate"
            >
              <i className="bx bx-copy font-size-22" />
            </Button>

            <Button
              type="button"
              color="white"
              className="ms-1 btn "
              title="delete"
              onClick={() => {
                handleDelete(item._id)
              }}
            >
              <i
                className="bx bx-trash me-2"
                style={{ color: "red", fontSize: "20px" }}
              />
            </Button>
          </>
        )}
      </div>
    ),
  }))

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deletePages(isOpen?.id))
  }

  const handleDelete = id => {
    setIsOpen({ stauts: true, id: id })
  }

  const isMult = LanguageSwitch()?.isMult

  columns[0].text = isMult ? "Primary Name" : "Name"

  const listChange = tab => {
    setSort(tab)
    history.push({
      pathname: location.pathname,
      search: `p=${1}&s=${tab}`,
      state: {
        page: 1,
        sort: tab,
      },
    })
  }

  const tableTitles = [
    {
      title: "All",
      value: "all",
    },

    {
      title: "Active",
      value: "active",
    },

    {
      title: "Inactive",
      value: "inactive",
    },

    {
      title: "Home",
      value: "home",
    },

    {
      title: "Deleted",
      value: "deleted",
    },
  ]

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.stauts}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={handleColumns()}
                  data={groupsData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col xl={6} lg={6} md={6} sm={12}>
                          <Col xl={6} lg={6} md={6} sm={6} className="mb-3">
                            <div
                              className="btn-group mt-2 mt-xl-0"
                              role="group"
                              aria-label="Basic radio toggle button group"
                            >
                              {map(tableTitles, (head, key) => (
                                <Fragment key={key}>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="btnradio"
                                    id={head.value + key}
                                    autoComplete="off"
                                    checked={sort === head.value}
                                    onChange={() =>
                                      listChange(head?.value || "")
                                    }
                                  />
                                  <label
                                    className="btn btn-success d-flex align-items-center w-100"
                                    htmlFor={head.value + key}
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {head?.title}
                                  </label>
                                </Fragment>
                              ))}
                              {/* <input
                                type="radio"
                                className="btn-check"
                                name="btnradio"
                                id="btnradio2"
                                autoComplete="off"
                                onChange={() => handleTabChange("HomePages")}
                              />
                              <label
                                className="btn btn-success"
                                htmlFor="btnradio2"
                              >
                                Home
                              </label>
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnradio"
                                id="btnradio3"
                                autoComplete="off"
                                // onClick={() => deletedAll()}
                                onChange={() => handleTabChange("Deleted")}
                              />
                              <label
                                className="btn btn-success"
                                htmlFor="btnradio3"
                              >
                                Deleted
                              </label> */}
                            </div>
                          </Col>
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <AvForm>
                                <AvField
                                  name="searchText"
                                  placeholder="Search"
                                  type="text"
                                  onChange={e => setSearchText(e.target.value)}
                                />
                              </AvForm>
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <Col
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          className="d-flex align-items-center justify-content-end"
                        >
                          <div className="text-sm-end">
                            <Link to="/pages/product/create">
                              <Button
                                type="button"
                                color="success"
                                className="btn-rounded  mb-2 me-2"
                              >
                                <i className="mdi mdi-plus me-1" />
                                Create Pages
                              </Button>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          {pagesList?.length ? (
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <p className="my-4 mx-3 ">No Pages!</p>
                          )}
                          <MyPagination
                            totalPages={pages}
                            page={page}
                            setPage={setPage}
                          />
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ProductPagesList
