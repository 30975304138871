import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//actions
import { deleteState, getAllStates } from "store/actions"
import "../../assets/scss/datatables.scss"
import MyPagination from "components/Common/MyPagination"
import DeleteModal from "components/Common/DeleteModal"
import { defaultImg } from "assets/images"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import StateModal from "./Crud/StateModal"

const StateList = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")
  const [stateModal, setStateModal] = useState({
    status: false,
    data: {},
  })

  const { loading, states, districts, blogs, prodLoading } = useSelector(
    state => ({
      loading: state.Blogs.loading,
      states: state.Location.states,
      districts: state.Location.districts,
      prodLoading: state.Products.loading,
    })
  )
  console.log(states)
  const totalPages = Math.ceil(states?.total / 10)
  const pages = range(1, totalPages + 1)

  const [sort, setSort] = useState("all")

  useEffect(() => {
    dispatch(getAllStates(searchText, page, 10))
  }, [dispatch, page, searchText])

  const [isOpen, setIsOpen] = useState({ status: false, id: "" })

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteState(isOpen?.id))
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const tableData = map(states?.states, (item, index) => ({
    ...item,
    createdAt: moment(item.createdAt).format("ll"),
    action: (
      <div>
        <Link
          to={`#`}
          className="btn-md"
          onClick={() => setStateModal({ status: true, data: item })}
        >
          <i className="bx bx-edit me-2 " style={{ fontSize: "1.3rem" }} />
        </Link>
        <Button
          id={`Delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: "red", marginBottom: "10px", fontSize: "1.3rem" }}
          />
        </Button>
      </div>
    ),
  }))
  const debounceBlogSearch = debounce(value => setSearchText(value), 600)

  const { SearchBar } = Search

  const listChange = sortValue => {
    setSort(sortValue)
  }

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ data: {}, status: false })}
          onDeleteClick={handleDeleteEvent}
        />
        <StateModal
          onCloseClick={() => setStateModal({ status: false, data: {} })}
          show={stateModal?.status}
          updateData={stateModal?.data}
        />
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={"id"}
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2 justify-content-between">
                      <Col lg={4}>
                        <div className="search-box w-100 ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                onChange={e =>
                                  debounceBlogSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            className="btn-rounded  mb-2 me-2 create-btn"
                            onClick={() => setStateModal({ status: true })}
                          >
                            <i className="mdi mdi-plus me-1" />
                            Create State
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {states?.total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No States !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default StateList
