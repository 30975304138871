import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from "./actionTypes"

export const userForgetPassword = user => (
  console.log(user),
  {
    type: FORGET_PASSWORD,
    payload: { user },
  }
)

export const userForgetPasswordSuccess = forgetSuccessMsg => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: forgetSuccessMsg,
})

export const userForgetPasswordError = error => ({
  type: FORGET_PASSWORD_ERROR,
  payload: error,
})

export const updatePassword = (passwords, onCloseClick) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { passwords, onCloseClick },
  }
}

export const updatePasswordSuccess = message => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const updatePasswordError = message => {
  return {
    type: CHANGE_PASSWORD_ERROR,
    payload: message,
  }
}

export const resetPassword = (value, history, resetId) => ({
  type: RESET_PASSWORD,
  payload: { value, history, resetId },
})

export const resetPasswordSuccess = password => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: password,
})

export const resetPasswordError = error => ({
  type: RESET_PASSWORD_ERROR,
  payload: error,
})
