import { useEffect, useState } from "react"

function useTextTruncate(text, maxLength) {
  const [truncatedText, setTruncatedText] = useState(text)

  useEffect(() => {
    if (text?.length > maxLength) {
      setTruncatedText(text?.substring(0, maxLength) + "...")
    } else {
      setTruncatedText(text)
    }
  }, [])

  return truncatedText
}

export default useTextTruncate
