import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  FormGroup,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//actions
import {
  deleteLsg,
  getAllDistricts,
  getAllLsgs,
  getAllTaluks,
} from "store/actions"

import "../../assets/scss/datatables.scss"

import MyPagination from "components/Common/MyPagination"
import DeleteModal from "components/Common/DeleteModal"
import { defaultImg } from "assets/images"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import LsgModal from "./Crud/LsgModal"

const LsgList = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")
  const [lsgModal, setLsgModal] = useState({
    status: false,
    data: {},
  })

  const [districtSearch, setDistrictSearch] = useState("")
  const [districtSelect, setDistrictSelect] = useState("Search District")
  const [districtSelectId, setDistrictSelectId] = useState("")

  const { loading, lsgs, taluks, prodLoading, districts } = useSelector(
    state => ({
      loading: state.Blogs.loading,
      lsgs: state.Location.lsgs,
      prodLoading: state.Products.loading,
      taluks: state.Location.taluks,
      districts: state.Location.districts,
    })
  )
  // console.log(lsgs)
  const totalPages = Math.ceil(lsgs?.total / 10)
  const pages = range(1, totalPages + 1)

  const [sort, setSort] = useState("all")

  useEffect(() => {
    dispatch(getAllLsgs(searchText, districtSelectId, page, 10))
  }, [dispatch, page, searchText, sort, districtSelectId])

  const [isOpen, setIsOpen] = useState({ status: false, id: "" })

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteLsg(isOpen?.id))
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "district.name",
      text: "District Name",
      sort: true,
    },

    {
      dataField: "action",
      text: "Action",
    },
  ]

  const tableData = map(lsgs?.lsgs, (item, index) => ({
    ...item,
    createdAt: moment(item.createdAt).format("ll"),
    action: (
      <div>
        <Link
          to={`#`}
          className="btn-md"
          onClick={() => setLsgModal({ status: true, data: item })}
        >
          <i className="bx bx-edit me-2 " style={{ fontSize: "1.3rem" }} />
        </Link>
        <Button
          id={`Delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: "red", marginBottom: "10px", fontSize: "1.3rem" }}
          />
        </Button>
      </div>
    ),
  }))

  const debounceBlogSearch = debounce(value => setSearchText(value), 600)

  const { SearchBar } = Search

  const listChange = sortValue => {
    setSort(sortValue)
  }
  useEffect(() => {
    dispatch(getAllDistricts(districtSearch, "", page, 10))
  }, [dispatch, districtSearch])
  const debounceDistrictSearch = debounce(
    value => setDistrictSearch(value),
    600
  )
  const handleDistrictEnters = textEntered => {
    debounceDistrictSearch(textEntered)
  }
  function handlerDistrictFinalValue(event) {
    setDistrictSelect(event.label)
    setDistrictSelectId(event.value)
  }
  const DistrictOptions = [
    {
      label: "All district",
      id: "",
    },
    {
      options: (districts?.total >= 1 ? districts?.districts : [])?.map(
        (result, index) => ({
          key: index,
          label: result?.name,
          value: result?._id,
        })
      ),
    },
  ]
  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ data: {}, status: false })}
          onDeleteClick={handleDeleteEvent}
        />
        <LsgModal
          onCloseClick={() => setLsgModal({ status: false, data: {} })}
          show={lsgModal?.status}
          updateData={lsgModal?.data}
        />
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={"id"}
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2 justify-content-between">
                      <Col lg={3}>
                        <div className="search-box w-100 ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                onChange={e =>
                                  debounceBlogSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            {/* <SearchBar {...toolkitProps.searchProps} /> */}
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col className="mb-2 padding-3" lg={4}>
                        <FormGroup className="w-350">
                          <div className="ajax-select mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleDistrictEnters}
                              value={districtSelect}
                              placeholder={districtSelect}
                              onChange={handlerDistrictFinalValue}
                              options={DistrictOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />
                          </div>
                        </FormGroup>
                      </Col>

                      <Col lg={4}>
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            className="btn-rounded  mb-2 me-2 create-btn"
                            onClick={() => setLsgModal({ status: true })}
                          >
                            <i className="mdi mdi-plus me-1" />
                            Create Panchayat/Municipality
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {lsgs?.total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">
                            No Panchayat/Municipality !
                          </p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default LsgList
