import React from "react"
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import LsgDataTables from "./LsgDataTables"
import MetaTag from "components/Common/Meta-tag"

function Lsgs() {
  return (
    <>
      <MetaTag title={"All Panchayat/Municipality"} />

      <div className="page-content">
        <Breadcrumbs
          title="Dashboard"
          breadcrumbItem="All Panchayat/Municipality"
        />
        <Container fluid>
          <div className="container-fluid">
            <LsgDataTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Lsgs
