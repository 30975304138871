import {
  CREATE_DISTRICT,
  CREATE_DISTRICT_SUCCESS,
  CREATE_DISTRICT_FAIL,
  GET_ALL_DISTRICTS,
  GET_ALL_DISTRICTS_SUCCESS,
  GET_ALL_DISTRICTS_FAIL,
  UPDATE_DISTRICT,
  UPDATE_DISTRICT_SUCCESS,
  UPDATE_DISTRICT_FAIL,
  DELETE_DISTRICT,
  DELETE_DISTRICT_SUCCESS,
  DELETE_DISTRICT_FAIL,
  CREATE_STATE,
  CREATE_STATE_SUCCESS,
  CREATE_STATE_FAIL,
  GET_ALL_STATES,
  GET_ALL_STATES_SUCCESS,
  GET_ALL_STATES_FAIL,
  UPDATE_STATE,
  UPDATE_STATE_SUCCESS,
  UPDATE_STATE_FAIL,
  DELETE_STATE,
  DELETE_STATE_SUCCESS,
  DELETE_STATE_FAIL,
  CREATE_TALUK,
  CREATE_TALUK_SUCCESS,
  CREATE_TALUK_FAIL,
  GET_ALL_TALUKS,
  GET_ALL_TALUKS_SUCCESS,
  GET_ALL_TALUKS_FAIL,
  UPDATE_TALUK,
  UPDATE_TALUK_SUCCESS,
  UPDATE_TALUK_FAIL,
  DELETE_TALUK,
  DELETE_TALUK_SUCCESS,
  DELETE_TALUK_FAIL,
  CREATE_LSG,
  CREATE_LSG_SUCCESS,
  CREATE_LSG_FAIL,
  GET_ALL_LSGS,
  GET_ALL_LSGS_SUCCESS,
  GET_ALL_LSGS_FAIL,
  UPDATE_LSG,
  UPDATE_LSG_SUCCESS,
  UPDATE_LSG_FAIL,
  DELETE_LSG,
  DELETE_LSG_SUCCESS,
  DELETE_LSG_FAIL,
} from "./actionTypes"

// create DISTRICT
export const createDistrict = (district, onCloseClick) => ({
  type: CREATE_DISTRICT,
  payload: { district, onCloseClick },
})

export const createDistrictSuccess = district => ({
  type: CREATE_DISTRICT_SUCCESS,
  payload: district,
})

export const createDistrictFail = error => ({
  type: CREATE_DISTRICT_FAIL,
  payload: error,
})

// all District
export const getAllDistricts = (searchText, stateId, page, limit) => ({
  type: GET_ALL_DISTRICTS,
  payload: { searchText, stateId, page, limit },
})

export const getAllDistrictsSuccess = districts => ({
  type: GET_ALL_DISTRICTS_SUCCESS,
  payload: districts,
})

export const getAllDistrictsFail = error => ({
  type: GET_ALL_DISTRICTS_FAIL,
  payload: error,
})

// update DISTRICT
export const updateDistrict = (districtId, district, onCloseClick) => ({
  type: UPDATE_DISTRICT,
  payload: { districtId, district, onCloseClick },
})

export const updateDistrictSuccess = district => ({
  type: UPDATE_DISTRICT_SUCCESS,
  payload: district,
})

export const updateDistrictFail = error => ({
  type: UPDATE_DISTRICT_FAIL,
  payload: error,
})

// delete DISTRICT
export const deleteDistrict = districtId => ({
  type: DELETE_DISTRICT,
  districtId,
})

export const deleteDistrictSuccess = district => ({
  type: DELETE_DISTRICT_SUCCESS,
  payload: district,
})

export const deleteDistrictFail = error => ({
  type: DELETE_DISTRICT_FAIL,
  payload: error,
})

// create State
export const createState = (state, onCloseClick) => ({
  type: CREATE_STATE,
  payload: { state, onCloseClick },
})

export const createStateSuccess = state => ({
  type: CREATE_STATE_SUCCESS,
  payload: state,
})

export const createStateFail = error => ({
  type: CREATE_STATE_FAIL,
  payload: error,
})

// all State
export const getAllStates = (searchText, page, limit) => ({
  type: GET_ALL_STATES,
  payload: { searchText, page, limit },
})

export const getAllStatesSuccess = states => ({
  type: GET_ALL_STATES_SUCCESS,
  payload: states,
})

export const getAllStatesFail = error => ({
  type: GET_ALL_STATES_FAIL,
  payload: error,
})

// update State
export const updateState = (stateId, state, onCloseClick) => ({
  type: UPDATE_STATE,
  payload: { stateId, state, onCloseClick },
})

export const updateStateSuccess = state => ({
  type: UPDATE_STATE_SUCCESS,
  payload: state,
})

export const updateStateFail = error => ({
  type: UPDATE_STATE_FAIL,
  payload: error,
})

// delete State
export const deleteState = stateId => ({
  type: DELETE_STATE,
  stateId,
})

export const deleteStateSuccess = state => ({
  type: DELETE_STATE_SUCCESS,
  payload: state,
})

export const deleteStateFail = error => ({
  type: DELETE_STATE_FAIL,
  payload: error,
})

// create Taluk
export const createTaluk = (taluk, onCloseClick) => ({
  type: CREATE_TALUK,
  payload: { taluk, onCloseClick },
})

export const createTalukSuccess = taluk => ({
  type: CREATE_TALUK_SUCCESS,
  payload: taluk,
})

export const createTalukFail = error => ({
  type: CREATE_TALUK_FAIL,
  payload: error,
})

// all TALUK
export const getAllTaluks = (searchText, districtId, page, limit) => ({
  type: GET_ALL_TALUKS,
  payload: { searchText, districtId, page, limit },
})

export const getAllTaluksSuccess = taluks => ({
  type: GET_ALL_TALUKS_SUCCESS,
  payload: taluks,
})

export const getAllTaluksFail = error => ({
  type: GET_ALL_TALUKS_FAIL,
  payload: error,
})

// update TALUK
export const updateTaluk = (talukId, taluk, onCloseClick) => ({
  type: UPDATE_TALUK,
  payload: { talukId, taluk, onCloseClick },
})

export const updateTalukSuccess = taluk => ({
  type: UPDATE_TALUK_SUCCESS,
  payload: taluk,
})

export const updateTalukFail = error => ({
  type: UPDATE_TALUK_FAIL,
  payload: error,
})

// delete TALUK
export const deleteTaluk = talukId => ({
  type: DELETE_TALUK,
  talukId,
})

export const deleteTalukSuccess = taluk => ({
  type: DELETE_TALUK_SUCCESS,
  payload: taluk,
})

export const deleteTalukFail = error => ({
  type: DELETE_TALUK_FAIL,
  payload: error,
})

// create LSG
export const createLsg = (lsg, onCloseClick) => ({
  type: CREATE_LSG,
  payload: { lsg, onCloseClick },
})

export const createLsgSuccess = lsg => ({
  type: CREATE_LSG_SUCCESS,
  payload: lsg,
})

export const createLsgFail = error => ({
  type: CREATE_LSG_FAIL,
  payload: error,
})

// all Lsg
export const getAllLsgs = (searchText, districtId, page, limit) => ({
  type: GET_ALL_LSGS,
  payload: { searchText, districtId, page, limit },
})

export const getAllLsgsSuccess = lsgs => ({
  type: GET_ALL_LSGS_SUCCESS,
  payload: lsgs,
})

export const getAllLsgsFail = error => ({
  type: GET_ALL_LSGS_FAIL,
  payload: error,
})

// update Lsg
export const updateLsg = (lsgId, lsg, onCloseClick) => ({
  type: UPDATE_LSG,
  payload: { lsgId, lsg, onCloseClick },
})

export const updateLsgSuccess = lsg => ({
  type: UPDATE_LSG_SUCCESS,
  payload: lsg,
})

export const updateLsgFail = error => ({
  type: UPDATE_LSG_FAIL,
  payload: error,
})

// delete Lsg
export const deleteLsg = lsgId => ({
  type: DELETE_LSG,
  lsgId,
})

export const deleteLsgSuccess = lsg => ({
  type: DELETE_LSG_SUCCESS,
  payload: lsg,
})

export const deleteLsgFail = error => ({
  type: DELETE_LSG_FAIL,
  payload: error,
})
