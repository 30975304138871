import { AvField, AvForm } from "availity-reactstrap-validation"
import MetaTag from "components/Common/Meta-tag"
import React, { useState } from "react"
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { resetPassword } from "store/actions"
import { LocalLogo, profile, LocalLogoSm } from "assets/images"
import { useDispatch } from "react-redux"

function ResetPassword() {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  console.log(params)

  function handleValidSubmit(event, values) {
    dispatch(resetPassword(values, history, params?.id))
  }
  const [password, setPassword] = useState({
    pass: "",
    confirmPass: "",
  })
  const [showPass, setShowPass] = useState(false)

  return (
    <React.Fragment>
      <MetaTag title="Reset Password" />

      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-gray p-4">
                        <h5 className="text-gray">Welcome Back !</h5>
                        <p>change password</p>
                      </div>
                    </Col>

                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span
                          className="avatar-title rounded-circle "
                          style={{
                            backgroundColor: "#f8f9fa",
                          }}
                        >
                          <img src={LocalLogoSm} alt="logo" height="70" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <Row className="mb-3">
                        <Col
                          className={`col-12 col-sm-6 col-md-6 col-lg-6 mb-4`}
                        >
                          <AvField
                            label={"Password *"}
                            id={`input-id-password`}
                            className="filePathClass"
                            name={"newPassword"}
                            type={showPass === true ? "text" : "password"}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Password is Required",
                              },
                              minLength: {
                                value: 8,
                                errorMessage: "Minimum eight characters",
                              },
                            }}
                            onChange={e =>
                              setPassword({
                                ...password,
                                pass: e.target.value,
                              })
                            }
                          />
                        </Col>
                        <Col
                          className={`col-12 col-sm-6 col-md-6 col-lg-6 mb-4 position-relative `}
                        >
                          <AvField
                            label={"ConfirmPassword *"}
                            id={`input-id-"confirmPassword":`}
                            className="filePathClass  position-relative"
                            name={"confirmPassword"}
                            type={showPass === true ? "text" : "password"}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Confirm Password is Required",
                              },
                              pattern: {
                                value:
                                  password?.pass !== password?.confirmPass
                                    ? "^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[@$!%*?&])[A-Za-zd@$!%*?&]{8,10}$"
                                    : "",
                                errorMessage: "Password does not match",
                              },
                            }}
                            onChange={e =>
                              setPassword({
                                ...password,
                                confirmPass: e.target.value,
                              })
                            }
                          />
                          <div className="eye-icons mt-3">
                            <input
                              type={"checkbox"}
                              name="show"
                              id="showPass"
                              onChange={e => setShowPass(e.target.checked)}
                            />
                            <label
                              className="mx-2 font-size-11 mb-0 pb-0"
                              htmlFor="showPass"
                            >
                              Show Password
                            </label>
                          </div>
                        </Col>
                        <div className="d-flex align-items-center justify-content-end">
                          <Button
                            size="md"
                            color="success"
                            className="px-5"
                            type="submit"
                          >
                            Update
                          </Button>
                        </div>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} {}. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by
                  <a target="blank" href="https://osperb.com">
                    {` osperb`}
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResetPassword
