import { LanguageSwitch } from "hooks/LanguageSwitch"
import { map, range } from "lodash"
import moment from "moment"

import MyPagination from "components/Common/MyPagination"
import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Card, Col, Label, Row, Spinner } from "reactstrap"
import { getPageViewDetails } from "store/actions"
import noImage from "assets/images/Defualt/noimage3.png"

function PageView() {
  const dispatch = useDispatch()
  const params = useParams()
  const [page, setPage] = useState(1)

  const { loading, pageViewDetails } = useSelector(state => ({
    loading: state.Users.loading,
    pageViewDetails: state.Users.pageViewDetails,
  }))
  const total = pageViewDetails?.total ?? 0
  const role = sessionStorage.getItem("role")

  useEffect(() => {
    dispatch(getPageViewDetails(params?.id, page))
  }, [dispatch, params?.id, page])

  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  const PreColumns = [
    {
      dataField: "imageData",
      text: "Image",
    },
    {
      dataField: "productName",
      text: "Name",
    },
    {
      dataField: "createdAt",
      text: "Date",
    },
  ]

  const columns = LanguageSwitch()?.isMult
    ? PreColumns
    : PreColumns?.filter(i => i.text !== "Secondary Name")

  const pageViewdata = map(pageViewDetails?.pageViews, (item, index) => ({
    ...item,
    imageData: (
      <img
        className="rounded-circle header-profile-user"
        style={{ width: "70px", height: "70px" }}
        src={item?.product?.images ? item?.product?.images[0]?.url : noImage}
      />
    ),
    productName: <span>{item?.product?.primaryLang?.name}</span>,
    createdAt: (
      <h6 className="text-dark">
        {moment(item?.createdAt).format("l h:mm A")}
      </h6>
    ),
  }))
  return (
    <div>
      <Row>
        <Col>
          <Card
            className="shadow p-3 mb-5 bg-white rounded"
            style={{ width: "100%" }}
          >
            <Label className="text-muted">Page View</Label>
            <Label>
              <>
                <Col>
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={pageViewdata}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        {loading ? (
                          <Spinner
                            color="secondary"
                            className="d-block m-auto"
                          />
                        ) : (
                          <>
                            <Row>
                              <Col>
                                <div className="table-responsive">
                                  <BootstrapTable
                                    // keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                            {pageViewDetails?.total === 0 && (
                              <p className="text-warning mt-1">
                                Product has no Page views
                              </p>
                            )}
                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        )}
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </Col>
              </>
            </Label>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default PageView
