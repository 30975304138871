import { AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

//actions
import {
  getPromoterDetails,
  getPromoterProfileDetails,
  promoterProfileUpdate,
  updatePromoter,
} from "store/actions"
import MetaTag from "components/Common/Meta-tag"
import Form from "./Form"
import SocialForm from "./SocialForm"

const UpdatePromoter = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const role = sessionStorage.getItem("role")

  const { promoterDetails, promoterLoading, loading, promoterProfile } =
    useSelector(state => ({
      promoterDetails: state?.Users?.promoterDetails,
      loading: state.Users.promoterLoad,
      promoterLoading: state.Dashboard.promoterLoading,

      promoterProfile: state.Dashboard.promoterProfile,
    }))

  const [countryCode, setCountryCode] = useState(null)
  const [talukId, setTalukId] = useState("")
  const [lsgId, setLsgId] = useState("")
  const [districtId, setDistrictId] = useState("")
  const [stateId, setStateId] = useState("")

  function handleValidSubmit(values) {
    const finalPromoterData = {
      ...values,
      promoterPercentage: parseInt(values?.promoterPercentage),
      countryCode: countryCode,
      taluk: talukId,
      lsg: lsgId,
      district: districtId,
      state: stateId,
    }

    if (role === "admin") {
      dispatch(updatePromoter(finalPromoterData, promoterDetails?._id, history))
    } else {
      console.log(values)
      dispatch(promoterProfileUpdate(values, history))
    }
  }

  const handleData = () => {
    if (role === "admin") {
      return promoterDetails
    } else if (role === "promoter") {
      return promoterProfile
    }
  }

  useEffect(() => {
    if (role === "admin") {
      dispatch(getPromoterDetails(params?.id))
    }
  }, [dispatch])

  useEffect(() => {
    if (role === "promoter") {
      dispatch(getPromoterProfileDetails())
    }
  }, [role, dispatch])

  useEffect(() => {
    setCountryCode(promoterDetails?.countryCode)
  }, [promoterDetails])

  useEffect(() => {
    setCountryCode(promoterProfile?.countryCode)
  }, [promoterProfile])
  console.log(loading)

  return (
    <>
      <MetaTag title={"Update Promoter"} />

      <div className="page-content">
        <Breadcrumbs title="Promoters" breadcrumbItem="Update Promoter" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row>
                <Col lg={6} md={6}>
                  <Card>
                    <CardBody>
                      <Form
                        updateData={handleData()}
                        isUpdate={true}
                        formData={{
                          setCountryCode,
                          countryCode,
                          setTalukId,
                          talukId,
                          setLsgId,
                          setDistrictId,
                          districtId,
                          setStateId,
                          stateId,
                        }}
                      />
                    </CardBody>
                  </Card>
                </Col>
                {role === "promoter" && (
                  <Col lg={6} md={6}>
                    <Card style={{ height: 410 }}>
                      <CardBody>
                        <CardTitle className="ms-3 mb-3">
                          Social Links
                        </CardTitle>
                        <SocialForm updateData={handleData()} />
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="btn px-5 btn-success save-customer"
                    >
                      {loading ||
                        (promoterLoading && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </>
                        ))}
                      {handleData()?.isVerified === true
                        ? `Update`
                        : `Update & Approve`}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdatePromoter

{
  /* <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">
                      Fill this form
                    </CardTitle>
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <Form
                        updateData={promoterDetails}
                        isUpdate={true}
                        formData={{
                          setCountryCode,
                          countryCode,
                        }}
                      />

                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Update
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card> */
}
